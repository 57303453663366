import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { Alert, AlertService } from './core';
import { AlertsComponent } from './shared';

@Component({
    selector: 'ag-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    @ViewChild('alertsComponent') alertsComponent: AlertsComponent;
    lastShownAlertIndex = 0;
    layout = 'simple';

    constructor(private router: Router,
                private alertService: AlertService,
                private translateService: TranslateService) 
    {
        this.translateService.addLangs(['en', 'fr']);
        this.translateService.setDefaultLang('en');

        const browserLang: string = this.translateService.getBrowserLang();
        this.translateService.use(browserLang.match(/en|fr/) ? browserLang : 'en');

        this.translateService.getTranslation(this.translateService.currentLang);
    }

    ngOnInit() {
        this.router.events.subscribe((event: NavigationEnd) => {
            // Handle switch between full layout and simple layout
            if (event instanceof NavigationEnd) 
            {
                this.layout = AppComponent.getLayoutTypeOfUrl(event.urlAfterRedirects)

                window.scrollTo(0, 0);
            }
        });

        this.alertService.getAlerts().subscribe((alerts: Alert[]) => {
            if (alerts && alerts.length > 0) {
                // Loop in alerts that haven't already being pushed
                while (this.lastShownAlertIndex < alerts.length) {
                    this.alertsComponent.show(alerts[this.lastShownAlertIndex]);
                    this.lastShownAlertIndex++;
                }
            }
        });
    }

    private static getLayoutTypeOfUrl(url: string): string
    {
        let simpleLayoutRoutes = [
            '/login',
            '/not-found',
            '/server-error',
            '/unauthorized',
            '/login/password-reset',
            '/login/password-set',
            '/invite',
        ];

        for(let route of simpleLayoutRoutes)
        {
            if(url.startsWith(route))
            {
                return 'simple'
            }
        }

        return 'full'
    }
}
