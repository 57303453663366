import {
    Directive,
    ElementRef,
    HostBinding,
    HostListener,
    Input,
    OnDestroy
} from '@angular/core';
import {
    NavigationEnd,
    Router,
} from '@angular/router';
import { Subscription } from 'rxjs';

@Directive({
    selector: '[agButtonLoader]'
})
export class ButtonLoaderDirective implements OnDestroy {
    @Input('agButtonLoader') agButtonLoader;
    @Input('isLoading') isLoading?: boolean;
    @Input('loaderMode') loaderMode = 'navigation';

    private isNavigationLoading = false;
    private routeSub$: Subscription;

    constructor(private button: ElementRef, private router: Router) {
        if (this.loaderMode === 'navigation') {
            this.routeSub$ = this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.isNavigationLoading = false;
                }
            });
        }
    }

    @HostBinding('class.is-loading')
    get getIsLoading() {
        if (this.loaderMode === 'navigation') {
            return this.isNavigationLoading;
        } else if (this.loaderMode === 'manual') {
            return this.isLoading;
        }
    }

    ngOnDestroy() {
        if (this.routeSub$) {
            this.routeSub$.unsubscribe();
        }
    }

    @HostListener('click')
    onClick() {
        if (this.loaderMode === 'navigation') {
            this.isNavigationLoading = true;
        }
    }
}
