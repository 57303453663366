import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { FormHelper } from '../../../../backendagyl/agyl-angular-utils/src';

import { AccountService, } from '../../shared';
import { Empty } from '../../shared/empty';
import { HttpErrorResponse } from '@angular/common/http';
import { NgZone } from '@angular/core';
import { PhoneNumberUtil } from 'google-libphonenumber';

@Component({
    selector: 'ag-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnInit {
    passwordResetErrorMessage: string;
    passwordResetForm: FormGroup;
    isPasswordResetSubmitting = false;

    constructor(private accountService: AccountService,
                private formBuilder: FormBuilder,
                private router: Router,
                private ngZone: NgZone) {
    }

    ngOnInit() 
    {
        this.passwordResetForm = this.formBuilder.group({
            'id': new FormControl('', [Validators.required, this.idValidator])
        });
    }
    
    idValidator(control: AbstractControl): ValidationErrors | null 
    {
        if(control.value == null || control.value.length == 0 || PasswordResetComponent.isValidNumber(control.value) || control.value.match(FormHelper.emailRegex))
        {
            return null
        }

        return { pattern: true };
    };

    static isValidNumber(value: String): boolean
    {
        try 
        {
            const phoneUtil = PhoneNumberUtil.getInstance();

            console.log(phoneUtil)

            let internationalNumber = value.replace(/[^0-9.]/g, '');
            const phoneNumberLength = internationalNumber.length;

            if (phoneNumberLength >= 10) 
            {
                if (phoneNumberLength === 10) 
                {
                    internationalNumber = `+1${internationalNumber}`;
                } 
                else 
                {
                    internationalNumber = `+${internationalNumber}`;
                }

                return phoneUtil.isValidNumber(phoneUtil.parse(internationalNumber));
            } 
        } 
        catch (e) 
        {
            return false
        }
    }

    onPasswordResetSubmit(formData) 
    {
        this.isPasswordResetSubmitting = true;

        let value = formData.id.toLowerCase()

        this.accountService.passwordReset(value, PasswordResetComponent.isValidNumber(value))
        .subscribe((empty: Empty) =>
        {
            this.ngZone.run(() => 
            {
                this.passwordResetErrorMessage = null;
                this.isPasswordResetSubmitting = false;
    
                this.router.navigate(['/login/password-set'], { queryParams: { id: value }, queryParamsHandling: 'merge' });
            })
        }, 
        (errorReponse: HttpErrorResponse) => 
        {
            this.ngZone.run(() => 
            {
                this.passwordResetErrorMessage = errorReponse.error.errorDescription;
                this.isPasswordResetSubmitting = false;
            })
        });
    }
}
