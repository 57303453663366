import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Terminal } from '../terminal';
import { Link } from './link-model';
import { SchedulePeriod } from './schedule/schedule-period-model';
import { Station } from './station-list.model';

/**
 * Services that handles stats actions
 */
@Injectable()
export class StationsService {
    constructor(private http: HttpClient) {
    }


    addToWhitelist(stationId: string, terminalId: string, userId: string): Observable<string> {
        return this.http.post<string>(`${environment.apiUrl}/v1/account/stations/${stationId}/terminals/${terminalId}/access`, { accountId: userId });
    }

    /**
     * Creates a station
     * @param {Station} station
     * @returns {Observable<string>}
     */
    createStation(station: Station): Observable<Station> {
        return this.http.post<Station>(`${environment.apiUrl}/v1/account/stations/`, station);
    }

    /**
     * Creates a terminal on specified station
     * @param {string} stationId
     * @param {Terminal} terminal
     * @returns {Observable<string>}
     */
    createTerminal(stationId: string, terminal: Terminal): Observable<string> {
        return this.http.post<string>(`${environment.apiUrl}/v1/account/stations/${stationId}/terminals`, terminal);
    }

    /**
     * Create a new schedule period
     * @param {string} stationId
     * @param {SchedulePeriod} schedulePeriod
     * @returns {Observable<string>}
     */
    createSchedulePeriod(stationId: string, schedulePeriod: any): Observable<string> 
    {
        return this.http.post<string>(`${environment.apiUrl}/v2/account/stations/${stationId}/hours`, schedulePeriod);
    }

    /**
     * Deletes a station with specified id
     * @param {string} stationId
     * @returns {Observable<null>}
     */
    deleteStation(stationId: string): Observable<null> {
        return this.http.delete<null>(`${environment.apiUrl}/v1/account/stations/${stationId}`);
    }

    /**
     * Deletes a terminal on specified station
     * @param {string} stationId
     * @param {string} terminalId
     * @returns {Observable<null>}
     */
    deleteTerminal(stationId: string, terminalId: string): Observable<null> {
        return this.http.delete<null>(`${environment.apiUrl}/v1/account/stations/${stationId}/terminals/${terminalId}`);
    }

    deleteWhitelist(stationId: string, terminalId: string, userId: string) {
        return this.http.delete<null>(`${environment.apiUrl}/v1/account/stations/${stationId}/terminals/${terminalId}/access/${userId}`);
    }

    /**
     * Get stations
     * @return {Observable<Station[]>>}
     */
    getStations(limit?: number, offset?: number, query?: string, all?: boolean): Observable<Station[]>
    {
        let params = new HttpParams();

        if (offset) {
            params = params.set('offset', offset.toFixed());
        }

        if (limit) {
            params = params.set('limit', limit.toFixed());
        }

        if (all) {
            params = params.set('all', 'true');
        }

        if (query) {
            params = params.set('q', query);
        }

        return this.http.get<Station[]>(`${environment.apiUrl}/v1/account/stations`, { params: params });
    }

    /**
     * Get the specified station
     * @param {string} stationId
     * @returns {Observable<Station>}
     */
    getStation(stationId: string): Observable<Station> {
        return this.http.get<Station>(`${environment.apiUrl}/v1/account/stations/${stationId}`);
    }

    /**
     * Get the links for specified station
     * @param {string} stationId
     * @returns {Observable<Link[]>}
     */
    getLinks(stationId: string): Observable<Link[]> 
    {
        return this.http.get<Link[]>(`${environment.apiUrl}/v1/account/stations/${stationId}/links`);
    }

    /**
     * Get the schedules for specified station
     * @param {string} stationId
     * @returns {Observable<Link[]>}
     */
    getSchedules(stationId: string): Observable<SchedulePeriod[]> 
    {
        return this.http.get<SchedulePeriod[]>(`${environment.apiUrl}/v2/account/stations/${stationId}/hours`);
    }

    /**
     * Get the link for specified id
     * @param {string} stationId
     * @param {string} linkId
     * @returns {Observable<Link>}
     */
    getLink(stationId: string, linkId: string): Observable<Link> 
    {
        return this.http.get<Link>(`${environment.apiUrl}/v1/account/stations/${stationId}/links/${linkId}`);
    }

    /**
     * Get the schedule period for specified id
     * @param {string} stationId
     * @returns {Observable<Link[]>}
     */
    getSchedulePeriod(stationId: string, schedulePeriodId: string): Observable<SchedulePeriod> 
    {
        return this.http.get<SchedulePeriod>(`${environment.apiUrl}/v2/account/stations/${stationId}/hours/${schedulePeriodId}`);
    }

    /**
     * Get the specified terminal from the specified station
     * @param {string} stationId
     * @param {string} terminalId
     * @returns {Observable<Terminal>}
     */
    getTerminal(stationId: string, terminalId: string): Observable<Terminal> {
        return this.http.get<Terminal>(`${environment.apiUrl}/v1/account/stations/${stationId}/terminals/${terminalId}`);
    }

    putTerminal(terminal: Terminal): Observable<Terminal> {
        return this.http.put<Terminal>(`${environment.apiUrl}/v1/account/stations/${terminal.stationId}/terminals/${terminal.id}`, terminal);
    }

    getWhitelist(stationId: string, terminalId: string): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/v1/account/stations/${stationId}/terminals/${terminalId}/access`);
    }

    /**
     * Updates the specified station
     * @param {Station} station
     * @returns {Observable<string>}
     */
    updateStation(station: Station): Observable<string> {
        return this.http.put<string>(`${environment.apiUrl}/v1/account/stations/${station.id}`, station);
    }

    /**
     * Updates the terminal of a specified station
     * @param {string} stationId
     * @param {Terminal} terminal
     * @returns {Observable<string>}
     */
    updateTerminal(stationId: string, terminal: Terminal): Observable<string> {
        return this.http.put<string>(`${environment.apiUrl}/v2/account/stations/${stationId}/terminals/${terminal.id}`, terminal);
    }

    /**
     * Updates a specified schedule period
     * @param {string} stationId
     * @param {string} schedulePeriodId
     * @param {SchedulePeriod} schedulePeriod
     * @returns {Observable<string>}
     */
    updateSchedulePeriod(stationId: string, schedulePeriodId: string, schedulePeriod: any): Observable<string>
    {
        return this.http.put<string>(`${environment.apiUrl}/v2/account/stations/${stationId}/hours/${schedulePeriodId}`, schedulePeriod);
    }

    /**
     * Updates a specific link
     * @param {Link} link
     * @returns {Observable<string>}
     */
    updateLink(stationId: string, link: Link): Observable<string> 
    {
        return this.http.put<string>(`${environment.apiUrl}/v1/account/stations/${stationId}/links/${link.id}`, link);
    }

    /**
     * Create a new link
     * @param {Link} link
     * @returns {Observable<string>}
     */
    createLink(stationId: string, link: Link): Observable<string> 
    {
        return this.http.post<string>(`${environment.apiUrl}/v1/account/stations/${stationId}/links`, link);
    }

    resetTerminal(stationId: string, terminalId: string): Observable<string> {
        return this.http.post<string>(`${environment.apiUrl}/v1/account/stations/${stationId}/terminals/${terminalId}/reset`, {
            'type': 'Soft'
        });
    }

    uploadStationImage(stationId: string, fileToUpload: File): Observable<string>
    {
        const endpoint = `${environment.apiUrl}/v1/account/stations/${stationId}/image`;
        const formData: FormData = new FormData();

        formData.append('image', fileToUpload, fileToUpload.name);

        return this.http.post<string>(endpoint, formData)
    }

    /**
     * Delete a specified schedule period
     * @param {string} stationId
     * @param {string} schedulePeriodId
     * @returns {Observable<string>}
     */
    deleteSchedulePeriod(stationId: string, schedulePeriodId: string): Observable<string> 
    {
        return this.http.delete<string>(`${environment.apiUrl}/v1/account/stations/${stationId}/hours/${schedulePeriodId}`);
    }

    /**
     * Deletes a specific link
     * @param {Link} link
     * @returns {Observable<string>}
     */
    deleteLink(stationId: string, linkId: string): Observable<string> 
    {
        return this.http.delete<string>(`${environment.apiUrl}/v1/account/stations/${stationId}/links/${linkId}`);
    }
}
