import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[agLinkWithSub]'
})
export class LinkWithSubDirective {

    constructor() {}

    @HostListener('click', ['$event', '$event.target'])
    onClick(event: MouseEvent, targetElement: HTMLElement) {
        if (!targetElement) {
            return;
        }

        let element: HTMLElement = targetElement;
        do {
            // If a link was clicked
            if (element.tagName === 'A') {
                return;
            }

            // If element is the with-sub menu element
            if (element.classList.contains('with-sub')) {

                // Toggle sub menu
                if (element.classList.contains('opened')) {
                    element.classList.remove('opened');
                } else {
                    element.classList.add('opened');
                }
                return;
            }

            element = element.parentElement;
        }
        while (element);
    }
}
