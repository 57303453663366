<kendo-dialog *ngIf="showDialog" #confirmDialog (close)="onNoClick()" title="{{dialog.title}}">
  <p style="margin: 30px; text-align: center;">{{dialog.message}}</p>
  <kendo-dialog-actions>
    <button class="btn btn-default btn-half-with-margin" (click)="onNoClick()">
      {{dialog.noButtonText}}
    </button>
    <button class="btn btn-danger btn-half-with-margin" (click)="onYesClick()">
      {{dialog.yesButtonText}}
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
