import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../core';
import { InvitationAcceptedComponent } from './invitation-status/invitation-accepted.component';
import { InvitationDeclinedComponent } from './invitation-status/invitation-declined.component';
import { InvitationErrorComponent } from './invitation-status/invitation-error.component';
import { InvitationVerificationComponent } from './invitation-verification/invitation-verification.component';
import { OwnershipTransferComponent } from './ownership-transfer.component';

const ownershipTransferRoutes: Routes = [
    {
        path: 'invite',
        children: [
            {
                path: ':invitationId/:token',
                component: InvitationVerificationComponent
            },
            {
                path: 'transfer/owner/:invitationId',
                component: OwnershipTransferComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'accepted',
                component: InvitationAcceptedComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'declined',
                component: InvitationDeclinedComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'error',
                component: InvitationErrorComponent
            },
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(ownershipTransferRoutes)
    ],
    exports: [
        RouterModule
    ],
    providers: []
})
export class OwnershipTransferRoutingModule {
}
