import { Directive, DoCheck, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Directive({
    selector: '[agIfValidationError]'
})
export class IfValidationErrorDirective implements DoCheck {
    @Input() agIfValidationErrorPath?: string;
    @Input() agIfValidationErrorError?: string;

    private form: FormGroup;
    private hasView = false;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef
    ) {}

    ngDoCheck() {
        this.checkState();
    }

    get validationError(): string {
        return this.agIfValidationErrorError;
    }

    get validationPath(): string {
        return this.agIfValidationErrorPath;
    }

    @Input()
    set agIfValidationError(form: FormGroup) {
        this.form = form;
        this.checkState();
    }

    private checkState() {
        if (this.form) {
            const isInvalid = this.isInvalid(this.form);
            this.setView(isInvalid);
        } else {
            this.setView(true);
        }
    }

    private isInvalid(form: FormGroup): boolean {
        if (!form) {
            return false;
        }

        const control: AbstractControl = (this.validationPath) ? form.get(this.validationPath) : form;
        return control.touched && ((this.validationError) ? control.getError(this.validationError) : control.invalid);
    }

    /**
     * Add or delete element view
     * @param allowed
     */
    private setView(allowed: boolean) {
        if (allowed && !this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
        } else if (!allowed && this.hasView) {
            this.viewContainer.clear();
            this.hasView = false;
        }
    }
}
