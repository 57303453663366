import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable, Observer, of, Subject } from 'rxjs';
import { catchError, tap, share } from 'rxjs/operators';

import { AuthConfig } from './auth-config.model';
import { AuthTokens } from './auth-tokens.model';

/**
 * Service that handles auth for http client
 */
@Injectable()
export class AuthHttp {
    private authTokens: AuthTokens;
    private doRefresh$: Observable<AuthTokens>;

    tokens$: Subject<any> = new Subject<any>();

    get basicToken(): string {
        return `Basic ${this.authConfig.basicToken}`;
    }

    get bearerToken(): string {
        return `Bearer ${this.authTokens.accessToken}`;
    }

    get isAuthenticated(): boolean {
        return (this.authTokens && this.authTokens.refreshToken && this.authTokens.refreshToken.length > 0);
    }

    get isTokenExpired(): boolean {
        let expirationUnixMoment: moment.Moment = moment.unix(this.authTokens.issuedAt)
            .add(this.authTokens.expiresIn as number, 'second');
        return moment().isAfter(expirationUnixMoment);
    }

    get refreshUrl(): string {
        return this.authConfig.refreshUrl;
    }

    get whitelistedDomains(): Array<string | RegExp> {
        return this.authConfig.whitelistedDomains;
    }

    constructor(
        private authConfig: AuthConfig,
        private http: HttpClient,
    ) {}

    isWhitelistedDomain(request: HttpRequest<any>): boolean {
        let requestUrl: URL;
        
        try {
            requestUrl = new URL(request.url);

            const index = this.whitelistedDomains.findIndex(
                domain =>
                    typeof domain === 'string'
                        ? domain === requestUrl.host
                        : domain instanceof RegExp ? domain.test(requestUrl.host) : false
            )

            return index > -1;
        } catch (err) {
            // if we're here, the request is made
            // to the same domain as the Angular app
            // so it's safe to proceed
            return true;
        }
    }

    /**
     * Gets a valid unexpired tokens
     * @returns {Observable<AuthTokens>}
     */
    getTokens(): Observable<AuthTokens> 
    {
        // Validate if tokens are set
        if (!this.isAuthenticated) {
            return null;
        }

        // If token is expired, refresh it before returning token
        if (this.isTokenExpired) {

            return Observable.create((observer: Observer<AuthTokens>) => {

                let doRefresh$: Observable<AuthTokens> = this.doRefreshToken();

                doRefresh$.subscribe(() => {
                    observer.next(this.authTokens);
                    observer.complete();
                }, error => {
                    observer.error(error);
                    observer.complete();
                });

                return doRefresh$;
            });
        } else {
            return of(this.authTokens);
        }
    }

    /**
     * Set tokens object
     * @since 0.0.7
     * @param authTokens
     */
    setTokens(authTokens: AuthTokens) 
    {
        this.authTokens = authTokens;
    }

    /**
     * Refreshes tokens using current authTokens
     * @returns {Observable<AuthTokens>}
     */
    doRefreshToken(): Observable<AuthTokens> 
    {
        // If already refreshing, return refresh observable.
        // This prevents parallel requests calling causing multiple token refreshes
        if (!this.doRefresh$) {
            const body = { refreshToken: this.authTokens.refreshToken };

            const options = {
                headers: new HttpHeaders().set('Authorization', this.basicToken),
            };

            this.doRefresh$ = this.http.post<AuthTokens>(this.authConfig.refreshUrl, body, options)
                .pipe(
                    // If we get a success, store tokens and delete shared refresh call
                    tap((tokens?: AuthTokens) => {
                        if (tokens) {
                            // Update tokens with refreshed tokens
                            this.setTokens(tokens);
                            this.tokens$.next(tokens);
                        } else {
                            // Update tokens with refreshed tokens
                            this.setTokens(null);
                            this.tokens$.next(null);
                        }

                        // Delete shared doRefresh call
                        this.doRefresh$ = null;
                    }),
                    // If we get an error, delete tokens and delete shared refresh call
                    catchError((error: any) => {
                        this.setTokens(null);
                        this.tokens$.next(null);

                        this.doRefresh$ = null;

                        return Observable.throw(error);
                    }),
                    // Share subscription
                    share()
                );
        }

        return this.doRefresh$;
    }
}
