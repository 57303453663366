import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
} from '@angular/router';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { Station } from './station-list.model';
import { StationListStore } from './station-list.store';

@Injectable()
export class StationResolver implements Resolve<Station|boolean> {
    constructor(private stationListStore: StationListStore) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Station|boolean> 
    {
        return of(this.stationListStore.getStations().find(stationFromList => stationFromList.id === route.params['stationId']));
    }
}
