import { NgModule } from '@angular/core';

import { BoolToStringPipe } from './bool-to-string.pipe';
import { ConfigValueToStringPipe } from './config/config-value-to-string.pipe';
import { LangToStringPipe } from './lang-to-string.pipe';
import { ObjectInArrayPipe } from './object-in-array.pipe';
import { RoundPipe } from './round.pipe';
import { SecondsToMinutesPipe } from './secondsToMinutes.pipe';
import { TextAreaToHtmlPipe } from './textArea-to-html.pipe';
import { UnixDateToStringPipe } from './unix-date-to-string.pipe';


const pipes = [
    BoolToStringPipe,
    ConfigValueToStringPipe,
    RoundPipe,
    SecondsToMinutesPipe,
    UnixDateToStringPipe,
    ObjectInArrayPipe,
    LangToStringPipe,
    TextAreaToHtmlPipe
];

@NgModule({
    imports: [
    ],
    declarations: [
        ...pipes
    ],
    exports: [
        ...pipes,
    ],
    providers: [
        UnixDateToStringPipe,
        ObjectInArrayPipe
    ],
})
export class SharedPipesModule {
}
