import {
    Component,
    EventEmitter,
    OnInit,
    ViewChild
} from '@angular/core';

import { DialogComponent } from '@progress/kendo-angular-dialog';
import { Observable, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { ConfirmDialog } from './confirm-dialog.model';

@Component({
    selector: 'ag-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
    @ViewChild('confirmDialog') confirmDialog: DialogComponent;

    dialog: ConfirmDialog;
    showDialog = false;

    private close: EventEmitter<boolean> = new EventEmitter();
    private subscription: Subscription;

    constructor() {
    }


    ngOnInit() {
    }

    onNoClick() {
        this.close.emit(false);
        this.showDialog = false;
    }

    onYesClick() {
        this.close.emit(true);
        this.showDialog = false;
    }

    show(confirmDialog: ConfirmDialog): Observable<boolean> {
        this.dialog = confirmDialog;
        this.showDialog = true;

        return this.close.pipe(first());
    }
}
