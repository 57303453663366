import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { SessionService } from './session.service';

/**
 * Service that is used to guard routes that force the user to logged in
 */
@Injectable()
export class AuthGuard implements CanActivate 
{
    constructor(private sessionService: SessionService, private router: Router)
    {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) 
    {
        if (this.sessionService.getLoginStatus()) 
        {
            return true;
        }

        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}
