import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

/**
 * Converts a bool to string
 */
@Pipe({
    name: 'boolToString'
})

export class BoolToStringPipe implements PipeTransform {
    constructor(
        private translate: TranslateService
    ) {}

    transform(input: boolean): string {
        if (this.translate.currentLang === 'en') {
            if (input) {
                return 'Yes';
            } else {
                return 'No';
            }
        } else {
            if (input) {
                return 'Oui';
            } else {
                return 'Non';
            }
        }

    }
}
