import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ConfigService } from '../../core';

/**
 * Converts a config input to string
 */
@Pipe({
    name: 'configValueToString'
})
export class ConfigValueToStringPipe implements PipeTransform {
    constructor(
        private configService: ConfigService
    ) {}

    transform(input: string, type: string, mediaId?: string): Observable<string> {
        let returnValue;

        switch (type) {
            case 'language':
                returnValue = this.configService.getLanguageLabelFromValue(input);
                break;
            default:
                returnValue = of(input);
                break;
        }
        return returnValue
            .map((output?: string) => {
                return output || input;
            });
    }
}
