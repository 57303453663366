import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '../shared/shared.module';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { OwnershipTransferComponent } from './ownership-transfer.component';
import { OwnershipTransferRoutingModule } from './ownership-transfer-routing.module';
import { InvitationVerificationComponent } from './invitation-verification/invitation-verification.component';
import { InvitationAcceptedComponent } from './invitation-status/invitation-accepted.component';
import { InvitationDeclinedComponent } from './invitation-status/invitation-declined.component';
import { InvitationErrorComponent } from './invitation-status/invitation-error.component';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        SharedModule,
        ReactiveFormsModule,
        TranslateModule,
        NgbDropdownModule,
        CommonModule
    ],
    declarations: [
        InvitationVerificationComponent,
        InvitationAcceptedComponent,
        InvitationDeclinedComponent,
        InvitationErrorComponent,
        OwnershipTransferComponent,
    ],
    exports: [
        OwnershipTransferComponent,
        OwnershipTransferRoutingModule
    ]
})
export class OwnershipTransferModule {
}
