<header *ngIf="displayName" class="site-header">
    <div class="container-fluid">
        <a href="" [routerLink]="['/']" class="site-logo">
            <img class="hidden-md-down" src="/assets/img/logo/LogoElmec.png" alt="Logo">
        </a>
        <button class="hamburger hamburger--htla" [ngClass]="{ 'is-active': mobileNavOpened }" (click)="onMobileNavClick()">
            <span>toggle menu</span>
        </button>

        <div class="container-fluid" id="dropdown-container">
            <!-- USER DROP DOWN -->
            <div ngbDropdown>
                <button type="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                        ngbDropdownToggle>
                    <span class="font-icon glyphicon glyphicon-user"></span>
                    {{ displayName }}
                </button>

                <div ngbDropdownMenu aria-labelledby="dd-user-menu">
                    <a class="dropdown-item" href="" (click)="onLogoutClick($event)">
                        <i class="font-icon glyphicon glyphicon-log-out"></i>
                        <span translate>HEADER.LOGOUT</span>
                    </a>
                </div>
            </div>
            <!-- /USER DROP DOWN -->

            <!-- LANG DROP DOWN -->
            <div ngbDropdown>
                <button type="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                        ngbDropdownToggle>
                    <span>{{ currentLanguage }}</span>
                </button>

                <div ngbDropdownMenu aria-labelledby="dropdown-lang">
                    <button *ngFor="let language of languageOptions$ | async"
                            class="dropdown-item"
                            type="button"
                            aria-expanded="false"
                            [disabled]="isCurrentLanguage(language.value)"
                            (click)="onLanguageSelected(language.value)">
                        <span class="dropdown-lang">{{ language.label }}</span>
                    </button>
                </div>
            </div>
            <!-- /LANG DROP DOWN -->
        </div>
    </div><!--.container-fluid-->
</header><!--.site-header-->
<aside *ngIf="displayName" [ngClass]="{ 'menu-left-opened': mobileNavOpened }">
    <div class="mobile-menu-left-overlay"></div>
    <nav class="side-menu">
        <!-- MAIN NAV START -->
        <section>

            <!-- ADMIN NAV START -->
            <ul class="side-menu-list">
                <li class="purple" routerLinkActive="opened" [routerLinkActiveOptions]="{ exact: true }">
                    <a href="" [routerLink]="['/']" (click)="onNavigateClick($event)">
                        <i class="fa fa-home fa-fw" aria-hidden="true"></i>
                        <span class="lbl">{{ 'HOME.TITLE' | translate }}</span>
                    </a>
                </li>
                <li class="green" routerLinkActive="opened">
                    <a href="" [routerLink]="['/stations']" (click)="onNavigateClick($event)">
                        <i class="fa fa-plug" aria-hidden="true"></i>
                        <span class="lbl">Stations</span>
                    </a>
                </li>
                <li class="dirty-blue" routerLinkActive="opened">
                    <a href="" [routerLink]="['/users']" (click)="onNavigateClick($event)">
                        <i class="fa fa-user" aria-hidden="true"></i>
                        <span class="lbl">{{ 'USERS.TITLE' | translate }}</span>
                    </a>
                </li>
                <li class="red" routerLinkActive="opened">
                    <a href="" [routerLink]="['/session-history']" (click)="onNavigateClick($event)">
                        <i class="fa fa-file-text" aria-hidden="true"></i>
                        <span class="lbl">{{ 'REPORTS.TITLE' | translate }}</span>
                    </a>
                </li>
                <li class="grey" routerLinkActive="opened">
                    <a href="" [routerLink]="['/settings']" (click)="onNavigateClick($event)">
                        <i class="fa fa-cog" aria-hidden="true"></i>
                        <span class="lbl">{{ 'SETTINGS.TITLE' | translate }}</span>
                    </a>
                </li>
            </ul>
            <!-- ADMIN NAV END -->

        </section>
        <!-- MAIN NAV END -->
    </nav><!--.side-menu-->
</aside>
