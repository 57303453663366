import { Directive, HostBinding, Input } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Directive({ selector: '[agValidationState]' })
export class ValidationStateDirective {
    @Input() agValidationState: FormGroup;
    @Input() validationPath?: string;

    constructor() {
    }

    @HostBinding('class.has-danger')
    get hasDanger(): boolean {
        if (!this.agValidationState) {
            return false;
        }

        if (this.validationPath) {
            const control: AbstractControl = this.agValidationState.get(this.validationPath);
            return control.touched && control.invalid;
        } else {
            return this.agValidationState.touched && this.agValidationState.invalid;
        }
    }
}
