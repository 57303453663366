import { HttpClient } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AgmCoreModule } from '@agm/core';


import { HeaderModule } from './header/header.module';
import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { LoginModule } from './login/login.module';

import { AppRoutingModule } from './app-routing.module';
import { OwnershipTransferModule } from './ownership-transfer/ownership-transfer.module';
import { IntlModule } from '@progress/kendo-angular-intl';

import '@progress/kendo-angular-intl/locales/en/all';
import '@progress/kendo-angular-intl/locales/fr/all';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AgmCoreModule.forRoot(
        {
            apiKey: 'AIzaSyBdqjAP83L0ma50K0_YJMp0gDAFlQ91_50',
            libraries: ["places"],
        }),
        CoreModule.forRoot(),
        LeafletModule,
        NgbModule,
        SharedModule,
        HeaderModule,
        IntlModule,
        LoginModule,
        RouterModule,
        OwnershipTransferModule,

        // Third party
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            },
            useDefaultLang: true
        }),

        AppRoutingModule,
    ],
    providers: [{
        provide: LOCALE_ID,
        useValue: 'en-US'
    }],
    bootstrap: [AppComponent],
})
export class AppModule {
}
