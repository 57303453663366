import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

/**
 * Converts unix date input to string
 */

@Pipe({
    name: 'unixDateToString'
})

export class UnixDateToStringPipe implements PipeTransform {
    constructor() {

    }

    transform(input: number, format: string): string {
        let returnedValue = '';
        returnedValue = moment.unix(input).format(format);
        return returnedValue;
    }
}
