import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormHelper } from '../../../../backendagyl/agyl-angular-utils/src';

import { AccountService, } from '../../shared';
import { Empty } from '../../shared/empty';
import { HttpErrorResponse } from '@angular/common/http';
import { NgZone } from '@angular/core';
import { PhoneNumberUtil } from 'google-libphonenumber';

@Component({
    selector: 'ag-password-set',
    templateUrl: './password-set.component.html',
    styleUrls: ['./password-set.component.scss'],
})
export class PasswordSetComponent implements OnInit {
    passwordSetErrorMessage: string;
    passwordSetForm: FormGroup;
    idValue: string
    isPasswordSubmitting = false;

    constructor(private route: ActivatedRoute,
                private accountService: AccountService,
                private formBuilder: FormBuilder,
                private router: Router,
                private ngZone: NgZone) {
    }

    ngOnInit() 
    {
        this.passwordSetForm = this.formBuilder.group({
            'id': new FormControl('', [Validators.required, this.idValidator]),
            'temporaryPassword': new FormControl('', [Validators.required]),
            'newPassword': new FormControl('', [Validators.required]),
            'newPasswordConfirm': new FormControl('', [Validators.required])
        });

        this.route.queryParams.subscribe(params => 
        {
            this.passwordSetForm.patchValue(
            {
              id: params.id
            });
        })
    }
    
    idValidator(control: AbstractControl): ValidationErrors | null 
    {
        if(control.value == null || control.value.length == 0)
        {
            return null
        }
        
        var isValidNumber = false

        try 
        {
            const phoneUtil = PhoneNumberUtil.getInstance();

            let internationalNumber = control.value.replace(/[^0-9.]/g, '');
            const phoneNumberLength = internationalNumber.length;

            if (phoneNumberLength >= 10) 
            {
                if (phoneNumberLength === 10) 
                {
                    internationalNumber = `+1${internationalNumber}`;
                } 
                else 
                {
                    internationalNumber = `+${internationalNumber}`;
                }

                isValidNumber = phoneUtil.isValidNumber(phoneUtil.parse(internationalNumber));
            } 
        } 
        catch (e) 
        {
            isValidNumber = false
        }

        if(isValidNumber || control.value.match(FormHelper.emailRegex))
        {
            return null
        }

        return { pattern: true };
    };

    onPasswordSubmit(formData) 
    {
        this.isPasswordSubmitting = true;

        this.accountService.passwordSet(formData.id.toLowerCase(), formData.temporaryPassword, formData.newPassword)
        .subscribe((empty: Empty) =>
        {
            this.ngZone.run(() => 
            {
                this.passwordSetErrorMessage = null;
                this.isPasswordSubmitting = false;
    
                this.router.navigate(['/']);
            })
        }, 
        (errorReponse: HttpErrorResponse) => 
        {
            this.ngZone.run(() => 
            {
                this.passwordSetErrorMessage = errorReponse.error.errorDescription;
                this.isPasswordSubmitting = false;
            })
        });
    }
}
