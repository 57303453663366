import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { HttpHelper } from '../../../../backendagyl/agyl-angular-utils/src';
import 'rxjs/add/operator/catch';
import * as _ from 'lodash';
import { CategoriesService, StationsService } from '../../shared';

import { CacheService } from '../cache/cache.service';
import { SelectOption } from './select-option.model';
import { Observable, of } from 'rxjs';
import { catchError, map, share } from 'rxjs/operators';
import { HttpHeaders, HttpParams } from '@angular/common/http';

/**
 * Service that handles application configurations
 */
@Injectable()
export class ConfigService {

    constructor(
        private cache: CacheService,
        private translate: TranslateService,
        private stationService: StationsService,
        private categoriesService: CategoriesService
    ) {}

    /**
     * Gets a list of all stations options
     * @returns {Observable<SelectOption[]>}
     */
    getCategoryOptions(): Observable<SelectOption[]> {
        const headers = HttpHelper.generateHeaders({ acceptLang: this.translate.currentLang });
        const options = {
            headers: new HttpHeaders().append('key', 'value'),
            params: new HttpParams().append('key', 'value')
        };
        options.params.append('all', 'true');

        const categoryOptions$ = this.categoriesService.getCategories(null,null,true)
            .pipe(map((res) => {
                const body = res.data || [];
                return _.map(body, (data: { id: string, name: string }) => {
                    return {
                        label: data.name,
                        value: data.id
                    };
                });
            }),
            catchError(error => this.handleError(error)),
            share());

        return this.cache.get(`CategoryOptions`, categoryOptions$);
    }


    /**
     * Gets a list of all stations options
     * @returns {Observable<SelectOption[]>}
     */
    getStationOptions(): Observable<SelectOption[]> {
        const headers = HttpHelper.generateHeaders({ acceptLang: this.translate.currentLang });
        const options = {
            headers: new HttpHeaders().append('key', 'value'),
            params: new HttpParams().append('key', 'value')
        };
        options.params.append('all', 'true');

        const categoryOptions$ = this.stationService.getStations(null,null,null,true)
            .pipe(map((stations) => {
                const body = stations || [];
                return _.map(body, (data: { id: string, name: string }) => {
                    return {
                        label: data.name,
                        value: data.id
                    };
                });
            }),
            catchError(error => this.handleError(error)),
            share());

        return this.cache.get(`StationOptions`, categoryOptions$);
    }


    /**
     * Gets a list of all language options
     * @returns {Observable<SelectOption[]>}
     */
    getLanguageOptions(): Observable<SelectOption[]> {
        return of(
            [
                {
                    label: 'English',
                    value: 'en',
                },
                {
                    label: 'Français',
                    value: 'fr'
                }
            ],
        );
    }

    /**
     * Gets language label related to the specified value
     * @param value
     * @returns {Reducer}
     */
    getLanguageLabelFromValue(value: string): Observable<string> {
        return this.getLanguageOptions()
            .pipe(map((options: Array<SelectOption>) => {
                return this.getLabelFromValue(options, value);
            }));
    }

    /**
     * Map options to select options
     * @param selectOptions
     * @param label
     * @returns {any}
     */
    private getLabelFromValue(selectOptions: Array<SelectOption>, label: string): string {
        const option: SelectOption = _.find(selectOptions, (selectOption: SelectOption) => {
            return selectOption.value === label;
        });

        if (option) {
            return option.label;
        }
        return null;
    }

    private handleError(error: Response | any) {
        const returnedError = {
            code: '',
            errMsg: ''
        };

        if (error instanceof error) {
            const body = error.error.json() || '';
            const err = body.errorDescription || JSON.stringify(body);
            returnedError.code = body.code;
            returnedError.errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
            returnedError.errMsg = error.message ? error.message : error.toString();
        }
        console.error(returnedError.errMsg);
        return Observable.throw(returnedError);
    }

}
