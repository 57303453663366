import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from '../../../environments/environment';

/**
 * Services that download reports
 */
@Injectable()
export class ReportsService 
{
    constructor(private http: HttpClient) 
    {
    }

    /**
     * Creates a report
     * @returns {Observable<Blob>}
     */
    createReport(month: number, year: number, type: string): Observable<Blob> 
    {
        var params = new HttpParams()

        params = params.set('month', (month + 1).toString());
        params = params.set('year', year.toString());
        
        return this.http.get(`${environment.apiUrl}/v1/account/reports/monthly/export/sessions/` + type, { params: params, responseType: "blob" });
    }
}