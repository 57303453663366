import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Observable, Observer } from 'rxjs';

import { Alert } from './alert.model';

@Injectable()

export class AlertService {
    private alerts: Alert[] = [];
    private observer: Observer<Alert[]>;
    private observable: Observable<Alert[]>;

    constructor(
        private translate: TranslateService
    ) {
        this.observable = new Observable<Alert[]>((observer: Observer<Alert[]>) => {
            this.observer = observer;
        });
    }

    handleCreateSuccess() {
        this.push({
            type: 'success',
            title: this.translate.instant('ALERT.SUCCESS.TITLE'),
            message: this.translate.instant('ALERT.SUCCESS.MESSAGE.CREATE')
        });
    }

    handleDeleteSuccess() {
        this.push({
            type: 'success',
            title: this.translate.instant('ALERT.SUCCESS.TITLE'),
            message: this.translate.instant('ALERT.SUCCESS.MESSAGE.DELETE')
        });
    }

    handleEditSuccess() {
        this.push({
            type: 'success',
            title: this.translate.instant('ALERT.SUCCESS.TITLE'),
            message: this.translate.instant('ALERT.SUCCESS.MESSAGE.EDIT')
        });
    }

    handleRefundSuccess() {
        this.push({
            type: 'success',
            title: this.translate.instant('ALERT.SUCCESS.TITLE'),
            message: this.translate.instant('ALERT.SUCCESS.MESSAGE.REFUND')
        });
    }

    handleError(error) {
        this.push({
            type: 'error',
            title: this.translate.instant('ALERT.ERROR.TITLE'),
            message: `${ error.code } - ${ error.errorDescription }`
        });
    }

    handleNotFound() {
        this.push({
            type: 'error',
            title: this.translate.instant('ALERT.ERROR.TITLE'),
            message: this.translate.instant('ALERT.ERROR.MESSAGE.NOT_FOUND')
        });
    }

    handleStatusChangeSuccess() {
        this.push({
            type: 'success',
            title: this.translate.instant('ALERT.SUCCESS.TITLE'),
            message: this.translate.instant('ALERT.SUCCESS.MESSAGE.STATUS_CHANGED')
        });
    }

    handleUploadSuccess() {
        this.push({
            type: 'success',
            title: this.translate.instant('ALERT.SUCCESS.TITLE'),
            message: this.translate.instant('ALERT.SUCCESS.MESSAGE.UPLOAD')
        });
    }

    getAlerts(): Observable<Alert[]> {
        return this.observable;
    }

    push(alert: Alert) {
        this.alerts.push(alert);
        this.observer.next(this.alerts);
    }
}
