import { Injectable } from '@angular/core';
import 'rxjs/add/operator/finally';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/share';


import { environment } from '../../../environments/environment';
import { Observable, of, Subject } from 'rxjs';
import { finalize, share, tap } from 'rxjs/operators';

interface CacheContent {
    expiry: number;
    value: any;
}

/**
 * Cache Service is an observables based in-memory cache implementation
 * Keeps track of in-flight observables and sets a default expiry for cached values
 * @export
 * @class CacheService
 */
@Injectable()
export class CacheService {
    private cache: Map<string, CacheContent> = new Map<string, CacheContent>();
    private inFlightObservables: Map<string, Observable<any>> = new Map<string, Observable<any>>();
    readonly DEFAULT_MAX_AGE: number = 60000;

    constructor() {

    }

    get enableLogging(): boolean {
        return !environment.production;
    }

    /**
     * Checks if the a key exists in cache
     */
    has(key: string): boolean {
        return this.cache.has(key);
    }

    /**
     * Gets the value from cache if the key is provided.
     * If no value exists in cache, then check if the same call exists
     * in flight, if so return the subject. If not create a new
     * Subject inFlightObservable and return the source observable.
     */
    get(key: string, fallback?: Observable<any>, maxAge?: number): Observable<any> | Subject<any> {
        if (this.hasValidCachedValue(key)) {
            if (this.enableLogging) {
                console.log(`%cGetting from cache ${key}`, 'color: green');
            }
            return of(this.cache.get(key).value);
        }

        if (!maxAge) {
            maxAge = this.DEFAULT_MAX_AGE;
        }

        if (this.inFlightObservables.has(key)) {
            if (this.enableLogging) {
                console.log(`%cGetting from cached observable ${key}`, 'color: blue');
            }
            return this.inFlightObservables.get(key);
        } else if (fallback && fallback instanceof Observable) {
            const call$ = fallback
                .pipe(tap((value) => {
                    this.set(key, value, maxAge);
                },
                finalize(() => {
                    this.inFlightObservables.delete(key);
                })));
            this.inFlightObservables.set(key, call$);
            if (this.enableLogging) {
                console.log(`%c Calling api for ${key}`, 'color: purple');
            }
            return call$;
        } else {
            return Observable.throw('Requested key is not available in Cache');
        }

    }


    /**
     * Sets the value with key in the cache
     * Notifies all observers of the new value
     */
    set(key: string, value: any, maxAge: number = this.DEFAULT_MAX_AGE): void {
        this.cache.set(key, { value: value, expiry: Date.now() + maxAge });
    }

    /**
     * Remove a value from the cache using the key
     */
    remove(key: string) {
        this.inFlightObservables.delete(key);
        this.cache.delete(key);
    }

    /**
     * Checks if the key exists and   has not expired.
     */
    private hasValidCachedValue(key: string): boolean {
        if (this.cache.has(key)) {
            if (this.cache.get(key).expiry < Date.now()) {
                this.cache.delete(key);
                return false;
            }
            return true;
        } else {
            return false;
        }
    }
}
