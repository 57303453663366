import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StationUtils } from '../shared';
import { Invitation } from '../shared/owner-transfer/invitation.model';
import { OwnershipTransferService } from '../shared/owner-transfer/owner-transfer.services';

@Component({
    selector: 'ag-ownership-transfer',
    templateUrl: './ownership-transfer.component.html',
    styleUrls: ['./ownership-transfer.component.scss'],
})
export class OwnershipTransferComponent implements OnInit 
{
    invitationId: string
    invitation: Invitation
    stationAddress: string
    shouldDeleteGuests = false

    constructor(private route: ActivatedRoute, 
                private router: Router, 
                private ownershipTransferService: OwnershipTransferService)
    {
    }

    ngOnInit() 
    {
        this.route.params.subscribe(params =>
            {
                this.invitationId = params['invitationId']
    
                this.ownershipTransferService.getInvitation(this.invitationId).subscribe(invitation => 
                {
                    this.invitation = invitation
                    this.stationAddress = StationUtils.getAddressStringFromAddress(invitation.station.address)
                }, 
                error =>
                {
                    this.onError(error);
                })
            })
    }

    onAcceptInvitation()
    {
        this.ownershipTransferService.acceptInvitation(this.invitationId, this.shouldDeleteGuests).subscribe(() => 
        {
            this.router.navigate(['invite/accepted/'], { queryParams: { stationName: this.invitation.station.name, currentOwnerName: this.invitation.currentOwner.name }});
        }, 
        error =>
        {
            this.onError(error);
        })
    }

    onDeclineInvitation()
    {

        this.ownershipTransferService.declineInvitation(this.invitationId).subscribe(() => 
        {
            this.router.navigate(['invite/declined/'], { queryParams: { stationName: this.invitation.station.name, currentOwnerName: this.invitation.currentOwner.name }});
        }, 
        error =>
        {
            this.onError(error);
        })
    }

    onError(error)
    {
        this.router.navigate(['invite/error/'], { queryParams: { errorMessage: error.error.errorDescription }});
    }

    onCheckboxChange(event)
    {
        this.shouldDeleteGuests = event.target.checked
    }
}
