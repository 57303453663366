<div class="alerts-container">
    <div *ngFor="let alert of shownAlerts;let index = index"
         class="alert alert-dismissible alert-border-left alert-fill alert-close fade show"
         role="alert"
         [ngClass]="{
            'alert-success': alert.type === 'success',
            'alert-info': alert.type === 'info',
            'alert-danger': alert.type === 'error',
            'alert-warning': alert.type === 'warning'
         }"
         (click)="onDismissClick(index)"
         @fadeInOut>

        <span class="icon-container" [ngSwitch]="alert.type">
            <i *ngSwitchCase="'success'" class="fa fa-check"></i>
            <i *ngSwitchCase="'info'" class="fa fa-info"></i>
            <i *ngSwitchCase="'error'" class="fa fa-exclamation-circle"></i>
            <i *ngSwitchCase="'warning'" class="fa fa-exclamation-triangle"></i>
        </span>

        <h4 class="alert-heading">{{ alert.title }}</h4>

        <p>{{ alert.message }}</p>
    </div>
</div>
