import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { DetailCurrentSession } from './detail-current-session.model';

/**
 * Services that handles stats actions
 */
@Injectable()
export class CurrentSessionsService {
    constructor(private http: HttpClient) {}


    getCurrentSession(currentSessionId: string): Observable<DetailCurrentSession> {
        return this.http.get<DetailCurrentSession>(`${environment.apiUrl}/v1/sessions/${currentSessionId}`);
    }

    /**
     * Get the current sessions information
     * @return {Observable<DetailCurrentSession>}
     */
    getCurrentSessions(): Observable<DetailCurrentSession[]> {
        return this.http.get<DetailCurrentSession[]>(`${environment.apiUrl}/v1/reports/sessions/current`);
    }
}
