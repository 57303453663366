import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { ConfigService, SelectOption, SessionService } from '../core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { CldrIntlService, IntlService } from '@progress/kendo-angular-intl';

@Component({
    selector: 'ag-header',
    templateUrl: 'header.component.html',
    styleUrls: ['header.component.scss'],
})
export class HeaderComponent implements OnInit {
    displayName: string;
    mobileNavOpened = false;
    languageOptions$: Observable<SelectOption[]>;


    constructor(private configService: ConfigService,
                private router: Router,
                private sessionService: SessionService,
                private translateService: TranslateService, 
                public intlService: IntlService,
                @Inject(LOCALE_ID) public localeId: string) {
    }

    get currentLanguage(): string {
        return this.translateService.currentLang.toUpperCase();
    }

    get showOffers(): boolean {
        return environment.showOffers;
    }

    ngOnInit() {
        this.sessionService.displayName$.subscribe(displayName =>
            {
                this.displayName = displayName
            })
            
        this.languageOptions$ = this.configService.getLanguageOptions();

        (<CldrIntlService>this.intlService).localeId = this.translateService.currentLang
    }

    isCurrentLanguage(languageCode: string): boolean {
        return this.translateService.currentLang === languageCode;
    }

    onLanguageSelected(languageCode: string) 
    {
        if (this.translateService.currentLang !== languageCode) 
        {
            languageCode = (languageCode === 'es') ? 'en' : languageCode;
            this.translateService.use(languageCode);
            this.localeId = languageCode;
            (<CldrIntlService>this.intlService).localeId = languageCode
        }
    }

    onLogoutClick(event: Event): void {
        event.preventDefault();
        this.sessionService.deleteSession();
        this.router.navigate(['/login']);
    }

    onMobileNavClick(): void {
        this.mobileNavOpened = !this.mobileNavOpened;
    }

    onNavigateClick(event: Event): void {
        this.mobileNavOpened = false;
    }
}
