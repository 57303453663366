import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { DataView } from '../../shared';

import { CategoryList } from './category-list.model';

/**
 * Services that handles stats actions
 */
@Injectable()
export class CategoriesService {
    constructor(private http: HttpClient) {
    }
    /**
     * Get offers
     * @return {Observable<DataView<CategoryList[]>>}
     */
    getCategories(limit?: number, offset?: number, all?: boolean): Observable<DataView<CategoryList[]>> {
        let params = new HttpParams();

        if (all) {
            params = params.set('all', 'true');
        }

        if (offset) {
            params = params.set('offset', offset.toFixed());
        }

        if (limit) {
            params = params.set('limit', limit.toFixed());
        }

        return this.http.get<DataView<CategoryList[]>>(`${environment.apiUrl}/admin/v1/categories`, {params: params});
    }
}
