import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

/**
 * Convert seconds to minutes
 */
@Pipe({
    name: 'secondsToMinutes'
})

export class SecondsToMinutesPipe implements PipeTransform {
    constructor(
    ) {}

    transform(input: number): any {
        return moment.duration(input, 'seconds').minutes() + ':' + moment.duration(input, 'seconds').seconds();
    }
}
