<div class="with-side-menu">
    <ag-header *ngIf="layout === 'full'"></ag-header>

    <!-- PAGE -->
    <router-outlet></router-outlet>
    <!-- /PAGE -->

    <ag-alerts #alertsComponent [duration]="5000"></ag-alerts>
</div>

<div kendoDialogContainer></div>
