import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { DetailStat } from './detail-stat.model';

/**
 * Services that handles stats actions
 */
@Injectable()
export class StatsService {
    constructor(private http: HttpClient) {}


    /**
     * Get the current week stats information
     * @return {Observable<DetailAccount>}
     */
    getStats(): Observable<DetailStat[]> {
        return this.http.get<DetailStat[]>(`${environment.apiUrl}/v1/reports/stats/week`);
    }
}
