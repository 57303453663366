import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '../shared/shared.module';
import { LoginComponent } from './login.component';
import { LoginRoutingModule } from './login-routing.module';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { PasswordSetComponent } from './password-set/password-set.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        SharedModule,
        ReactiveFormsModule,
        TranslateModule,
        LoginRoutingModule,
        NgbDropdownModule
    ],
    declarations: [
        LoginComponent,
        PasswordSetComponent,
        PasswordResetComponent
    ],
    exports: [
        LoginRoutingModule
    ]
})
export class LoginModule {
}
