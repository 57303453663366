import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

import { AuthConfig } from './auth-config.model';
import { AuthHttpInterceptor } from './auth-http.interceptor';
import { AuthHttp } from './auth-http.service';

@NgModule({
    imports: [HttpClientModule],
    providers: [
        AuthHttp,
    ]
})
export class AuthHttpModule {
    constructor(@Optional() @SkipSelf() parentModule: AuthHttpModule) {
        if (parentModule) {
            throw new Error(
                'AuthHttpModule is already loaded. Import it in the AppModule only');
        }
    }

    static forRoot(config: AuthConfig): ModuleWithProviders<AuthHttpModule> {
        return {
            ngModule: AuthHttpModule,
            providers: [
                {
                    provide: AuthConfig,
                    useValue: config,
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: AuthHttpInterceptor,
                    multi: true
                }
            ]
        };
    }
}