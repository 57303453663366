import { Injectable } from '@angular/core';
import { Station } from './station-list.model';

@Injectable()
export class StationListStore 
{
    private static stationListKey = "stationList"

    constructor() { }

    setStations(stations: Station[])
    {
        localStorage.setItem(StationListStore.stationListKey, JSON.stringify(stations))
    }

    getStations()
    {
        return JSON.parse(localStorage.getItem(StationListStore.stationListKey))
    }
}
