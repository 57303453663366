import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { DetailBill } from './detail-bill.model';

/**
 * Services that handles stats actions
 */
@Injectable()
export class BilledService {
    constructor(private http: HttpClient) {}

    /**
     * Get bills for specified date
     * @param {number} date
     * @returns {Observable<DetailBill>}
     */
    getBilled(month: number, year: number): Observable<DetailBill[]> {
        const options = {
            params: new HttpParams(),
        };

        if (month) {
            options.params = options.params.set('month', month.toString());
        }
        if (year) {
            options.params = options.params.set('year', year.toString());
        }

        return this.http.get<DetailBill[]>(`${environment.apiUrl}/v1/reports/stations/billed`, options);
    }
}
