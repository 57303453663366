export const environment = {
    app: 'evduty',
    production: false,
    apiBasicKey: 'UEVCUzJIZk53SkRwNWNwaGthV21rcXJEQTlxSG1aMzc6REJWNWtqZExReWpjODRGS3VKSko4a0FBdndGNjhRc0Q=',
    apiUrl: 'https://api-staging.evduty.net',
    apiKey: 'PEBS2HfNwJDp5cphkaWmkqrDA9qHmZ37',
    apiSecret: 'DBV5kjdLQyjc84FKuJJJ8kAAvwF68QsD',
    cookiePrefix: 'evduty',
    gridLimit: 10,
    whitelistedDomains: ['api-staging.evduty.net'],
    showOffers: true
};
