import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class SubscriptionService {

    constructor(private http: HttpClient) {
    }

    /***
     * Unsubscribe from SYNC-APP Monthly plan
     * @param {string} planId
     * @returns {Observable<any>}
     */
    unSubscribeToPlan(userId: string, planId: string): Observable<any> {
        return this.http.delete(`${environment.apiUrl}/admin/v1/accounts/${userId}/subscriptions/${planId}`);
    }
}
