import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { head } from "lodash";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { Invitation } from "./invitation.model";

/**
 * Services that handle ownership transfers
 */
@Injectable()
export class OwnershipTransferService 
{
    constructor(private http: HttpClient, private translateService: TranslateService) {}

    /**
     * Validate the specified invitation
     * @return {Observable<void>}
     */
    validateInvitation(invitationId: string, token: string, language: string): Observable<void> 
    {
        let headers = new HttpHeaders(
        {
            "accept-language": language
        })

        return this.http.get<void>(`${environment.apiUrl}/v1/account/stations/ownership/invite/${invitationId}/${token}`, {headers});
    }

    /**
     * Return the specified invitation
     * @return {Observable<Invitation>}
     */
    getInvitation(invitationId: string): Observable<Invitation> 
    {
        return this.http.get<Invitation>(`${environment.apiUrl}/v1/account/stations/ownership/invite/${invitationId}`);
    }

    /**
     * Accept the specified invitation
     * @return {Observable<void>}
     */
    acceptInvitation(invitationId: string, resetWhitelist: boolean): Observable<void> 
    {
        const resetObject = 
        {
            resetWhitelist: resetWhitelist
        }

        return this.http.put<void>(`${environment.apiUrl}/v1/account/stations/ownership/invite/${invitationId}/accept`, resetObject);
    }

    /**
     * Decline the specified invitation
     * @return {Observable<void>}
     */
    declineInvitation(invitationId: string): Observable<void> 
    {
        const resetObject = 
        {
            resetWhitelist: false
        }
        return this.http.put<void>(`${environment.apiUrl}/v1/account/stations/ownership/invite/${invitationId}/decline`, resetObject);
    }
}