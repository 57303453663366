<div class="page-center">
    <div class="page-center-in">
        <div *ngIf="loginForm" class="container-fluid">
            <form class="sign-box" [formGroup]="loginForm" (ngSubmit)="onLoginSubmit(loginForm.value)">

                <div class="sign-avatar">
                    <img src="assets/img/logo/LogoElmec.png" alt="Elmec Logo">
                </div>
                <header class="sign-title" translate>LOGIN.SIGN_IN</header>

                <!-- ERROR MESSAGES START -->
                <div *ngIf="loginErrorCode" class="alert alert-danger" role="alert">
                    <div [ngSwitch]="loginErrorCode">
                        <div *ngSwitchCase="123">
                            <span translate [translateParams]="{field: 'USERS.TYPE' | translate}">
                                FEEDBACK.MESSAGE.ERROR.NOT_FOUND
                            </span>
                        </div>
                        <div *ngSwitchCase="104">
                            <span translate [translateParams]="{field: 'LOGIN.PASSWORD.TITLE' | translate}">
                                FEEDBACK.VALIDATION.ERROR.PATTERN
                            </span>
                        </div>
                    </div>
                </div>
                <!-- ERROR MESSAGES END -->

                <!-- EMAIL -->
                <fieldset class="form-group" [agValidationState]="loginForm" [validationPath]="'email'">
                    <input class="form-control"
                           [placeholder]="'FIELD.EMAIL.PLACEHOLDER' | translate"
                           type="text"
                           formControlName="email">

                    <span *agIfValidationError="loginForm;path:'email';error:'required'"
                          class="form-control-feedback"
                          translate
                          [translateParams]="{field: 'FIELD.EMAIL.TITLE' | translate}">
                        FEEDBACK.VALIDATION.ERROR.REQUIRED
                    </span>

                    <span *agIfValidationError="loginForm;path:'email';error:'pattern'"
                          class="form-control-feedback"
                          translate
                          [translateParams]="{field: 'FIELD.ID.TITLE' | translate}">
                        FEEDBACK.VALIDATION.ERROR.PATTERN
                    </span>
                </fieldset>
                <!-- /EMAIL -->

                <!-- PASSWORD -->
                <fieldset class="form-group" [agValidationState]="loginForm" [validationPath]="'password'">
                    <input class="form-control"
                           type="password"
                           formControlName="password">

                    <span *agIfValidationError="loginForm;path:'password';error:'required'"
                          class="form-control-feedback"
                          translate
                          [translateParams]="{field: 'LOGIN.PASSWORD.TITLE' | translate}">
                        FEEDBACK.VALIDATION.ERROR.REQUIRED
                    </span>
                </fieldset>
                <!-- /PASSWORD -->

                <button class="btn btn-rounded" type="submit" [disabled]="isLoginSubmitting || !loginForm.valid">
                    <span translate>LOGIN.TITLE</span>
                    <i *ngIf="isLoginSubmitting" class="fa fa-spinner fa-spin"></i>
                </button>

                <div id="center">
                    <a id="password-link" routerLink="/login/password-reset">{{ 'LOGIN.FORGOT_PASSWORD' | translate }}</a>
                </div>


                <!-- LANG DROP DOWN -->
                <div id="dropdown-container">
                    <div ngbDropdown>
                        <button type="button"
                                aria-haspopup="true"
                                aria-expanded="false"
                                ngbDropdownToggle>
                            <span>{{ currentLanguage }}</span>
                        </button>
        
                        <div ngbDropdownMenu aria-labelledby="dropdown-lang">
                            <button *ngFor="let language of languageOptions$ | async"
                                    class="dropdown-item"
                                    type="button"
                                    aria-expanded="false"
                                    [disabled]="isCurrentLanguage(language.value)"
                                    (click)="onLanguageSelected(language.value)">
                                <span class="dropdown-lang">{{ language.label }}</span>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- /LANG DROP DOWN -->
            </form>
        </div>
    </div>
</div>
