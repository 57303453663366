import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: 'ag-invitation-accepted',
    templateUrl: './invitation-accepted.component.html',
    styleUrls: ['./invitation-accepted.component.scss'],
})
export class InvitationAcceptedComponent implements OnInit 
{
    stationName = ""
    currentOwnerName = ""

    constructor(private route: ActivatedRoute)
    {
        
    }

    ngOnInit()
    {
        this.route.queryParams.subscribe(params => 
        {
            this.stationName = params.stationName
            this.currentOwnerName = params.currentOwnerName
        })
    }
}
