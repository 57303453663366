import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './login.component';
import { PasswordSetComponent } from './password-set/password-set.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';

const loginRoutes: Routes = [
    {
        path: 'login',
        children: [
            {
                path: '',
                component: LoginComponent
            },
            {
                path: 'password-reset',
                component: PasswordResetComponent
            },
            {
                path: 'password-set',
                component: PasswordSetComponent
            }
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(loginRoutes)
    ],
    exports: [
        RouterModule
    ],
    providers: []
})
export class LoginRoutingModule {
}
