import { Pipe, PipeTransform } from '@angular/core';

/**
 * Round a number
 */
@Pipe({
    name: 'round'
})

export class RoundPipe implements PipeTransform {
    constructor(
    ) {}

    transform(input: number): number {
        return Math.round(input);
    }
}
