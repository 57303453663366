
<div *ngIf="invitation" class="container-fluid">
    <img id="logo" src="/assets/img/logo/LogoElmec.png" alt="Logo">

    <div class="center">
        <span id="title" translate>
            INVITATION.OWNERSHIP_TRANSFER
        </span>
    </div>
    <div class="center">
        <span id="main-text">
            <span><b>{{ invitation.currentOwner.name }}</b></span>
            <span translate>INVITATION.DESCRIPTION_1</span>
            <span><b>{{ invitation.station.name }}</b></span>
            <span translate>INVITATION.DESCRIPTION_2</span>
            <span><b>{{ stationAddress }}</b></span>
            <span translate>INVITATION.DESCRIPTION_3</span>
        </span>
    </div>
    <div class="center" *ngIf="invitation.isAccountReady">
        <label>
            <input type="checkbox" [value]="false" [checked]=shouldDeleteGuests  (change)="onCheckboxChange($event)"/>
            <span translate>INVITATION.DELETE_GUESTS</span>
        </label>
    </div>
    <div class="center" *ngIf="!invitation.isAccountReady">
        <label>
            <span class="center" [ngStyle]="{'color': 'darkorange'}" translate>INVITATION.ACCOUNT_INSTRUCTIONS_1</span>
            <span class="center" [ngStyle]="{'color': 'darkorange'}" translate>INVITATION.ACCOUNT_INSTRUCTIONS_2</span>
            <span class="center" [ngStyle]="{'color': 'darkorange'}" translate>INVITATION.ACCOUNT_INSTRUCTIONS_3</span>
            <br>
            <div>
                <span class="center" [ngStyle]="{'color': 'darkorange'}" translate>INVITATION.ACCOUNT_INSTRUCTIONS_4</span>
            </div>
            <br>
            <div>
                <span class="center" [ngStyle]="{'color': 'darkorange'}" translate>INVITATION.ACCOUNT_INSTRUCTIONS_5</span>
            </div>
            <br>
        </label>
    </div>
    <div class="center">
        <button class="btn btn-success" id="success" (click)="onAcceptInvitation()" [class.gray]="!invitation.isAccountReady" [disabled]="!invitation.isAccountReady" translate>INVITATION.ACCEPT</button>
        <button class="btn btn-danger" id="decline" (click)="onDeclineInvitation()" [class.gray]="!invitation.isAccountReady" [disabled]="!invitation.isAccountReady" translate>INVITATION.DECLINE</button>
    </div>
</div>
