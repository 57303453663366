<div class="page-center">
    <div class="page-center-in">
        <div *ngIf="passwordSetForm" class="container-fluid">
            <form class="sign-box" [formGroup]="passwordSetForm" (ngSubmit)="onPasswordSubmit(passwordSetForm.value)">
                <div class="sign-avatar">
                    <img src="assets/img/logo/LogoElmec.png" alt="Elmec Logo">
                </div>
                <header class="sign-title" translate>Please enter your old password and new password.</header>

                <!-- ERROR MESSAGES START -->
                <div *ngIf="passwordSetErrorMessage" class="alert alert-danger" role="alert">
                    <span>
                        {{ passwordSetErrorMessage }}
                    </span>
                </div>
                <!-- ERROR MESSAGES END -->

                <!-- ID -->
                <fieldset class="form-group" [agValidationState]="passwordSetForm" [validationPath]="'id'">
                    <input class="form-control"
                           [placeholder]="'FIELD.EMAIL.PLACEHOLDER' | translate"
                           type="text"
                           formControlName="id">

                    <span *agIfValidationError="passwordSetForm;path:'id';error:'required'"
                          class="form-control-feedback"
                          translate
                          [translateParams]="{field: 'FIELD.EMAIL.TITLE' | translate}">
                        FEEDBACK.VALIDATION.ERROR.REQUIRED
                    </span>

                    <span *agIfValidationError="passwordSetForm;path:'id';error:'pattern'"
                          class="form-control-feedback"
                          translate
                          [translateParams]="{field: 'FIELD.ID.TITLE' | translate}">
                        FEEDBACK.VALIDATION.ERROR.PATTERN
                    </span>
                </fieldset>
                <!-- /ID -->

                <!-- TEMPORARY_PASSWORD -->
                <span>Temporary password</span>
                <fieldset class="form-group">
                    <input class="form-control"
                           type="password"
                           formControlName="temporaryPassword">
                </fieldset>
                <!-- /TEMPORARY_PASSWORD -->

                <!-- NEW_PASSWORD -->
                <span>New password</span>
                <fieldset class="form-group">
                    <input class="form-control"
                            type="password"
                            formControlName="newPassword">
                </fieldset>
                <!-- /NEW_PASSWORD -->

                <!-- NEW_PASSWORD_CONFIRM -->
                <span>Confirm new password</span>
                <fieldset class="form-group">
                    <input class="form-control"
                            type="password"
                            formControlName="newPasswordConfirm">
                </fieldset>
                <!-- /NEW_PASSWORD_CONFIRM -->

                <button class="btn btn-rounded" type="submit" [disabled]="isPasswordSubmitting || !passwordSetForm.valid">
                    <span translate>Change Password</span>
                    <i *ngIf="isPasswordSubmitting" class="fa fa-spinner fa-spin"></i>
                </button>
            </form>
        </div>
    </div>
</div>
