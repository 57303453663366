import { Pipe, PipeTransform } from '@angular/core';

/**
 * Converts an object to array
 */

@Pipe({
    name: 'objectInArray'
})

export class ObjectInArrayPipe implements PipeTransform {
    constructor() {

    }

    transform(value) {
        const keys: any = [];
        for (const key of Object.keys(value)) {
            keys.push({key: key, value: value[key]});
        }
        return keys;
    }
}
