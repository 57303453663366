<div class="page-center">
    <div class="page-center-in">
        <div *ngIf="passwordResetForm" class="container-fluid">
            <form class="sign-box" [formGroup]="passwordResetForm" (ngSubmit)="onPasswordResetSubmit(passwordResetForm.value)">
                <div class="sign-avatar">
                    <img src="assets/img/logo/LogoElmec.png" alt="Elmec Logo">
                </div>
                <header class="sign-title" translate>Please enter your e-mail or phone number.</header>

                <!-- ERROR MESSAGES START -->
                <div *ngIf="passwordResetErrorMessage" class="alert alert-danger" role="alert">
                    <span>
                        {{ passwordResetErrorMessage }}
                    </span>
                </div>
                <!-- ERROR MESSAGES END -->

                <!-- ID -->
                <fieldset class="form-group" [agValidationState]="passwordResetForm" [validationPath]="'id'">
                    <input class="form-control"
                           [placeholder]="'FIELD.EMAIL.PLACEHOLDER' | translate"
                           type="text"
                           formControlName="id">

                    <span *agIfValidationError="passwordResetForm;path:'id';error:'required'"
                          class="form-control-feedback"
                          translate
                          [translateParams]="{field: 'FIELD.EMAIL.TITLE' | translate}">
                        FEEDBACK.VALIDATION.ERROR.REQUIRED
                    </span>

                    <span *agIfValidationError="passwordResetForm;path:'id';error:'pattern'"
                          class="form-control-feedback"
                          translate
                          [translateParams]="{field: 'FIELD.ID.TITLE' | translate}">
                        FEEDBACK.VALIDATION.ERROR.PATTERN
                    </span>
                </fieldset>
                <!-- /Id -->

                <button class="btn btn-rounded" type="submit" [disabled]="isPasswordResetSubmitting || !passwordResetForm.valid">
                    <span translate>Reset Password</span>
                    <i *ngIf="isPasswordResetSubmitting" class="fa fa-spinner fa-spin"></i>
                </button>
            </form>
        </div>
    </div>
</div>
