
import { Terminal } from '../terminal';

export interface Station {
    id?: string;
    address: Address;
    desc?: string;
    latitude: number;
    longitude: number;
    name: string;
    status?: string;
    statusName?: string;
    terminals?: Terminal[];
    imageUrl?: string
}

interface Address {
    city?: string;
    formattedAddress?: string;
    postalCode?: string;
    state?: string;
    street2?: string;
    street?: string;
    fullAddress?: string
}

export class StationUtils
{
    static getAddressStringFromAddress(address: Address): string
    {
        let fullAddress = ""

        fullAddress = this.addToStringIfNotEmpty(fullAddress, address.street)
        fullAddress = this.addToStringIfNotEmpty(fullAddress, address.street2)
        fullAddress = this.addToStringIfNotEmpty(fullAddress, address.city)
        fullAddress = this.addToStringIfNotEmpty(fullAddress, address.state)
        fullAddress = this.addToStringIfNotEmpty(fullAddress, address.postalCode)

        return fullAddress + ""
    }

    private static addToStringIfNotEmpty(input: string, valueToAdd: string)
    {
        if(valueToAdd != null && valueToAdd.length > 0)
        {
            if(input == null || input.length == 0)
            {
                return valueToAdd
            }

            return input + ", " + valueToAdd
        }

        return input
    }
}
