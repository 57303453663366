import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

/**
 * Converts a language to string
 */
@Pipe({
    name: 'langToString'
})

export class LangToStringPipe implements PipeTransform {
    constructor(
        private translate: TranslateService
    ) {}

    transform(input: string): string {
        if (this.translate.currentLang === 'en') {
            switch(input) {
                case 'fr':
                    return 'French';
                case 'en':
                    return 'English';
            }
        } else {
            switch(input) {
                case 'fr':
                    return 'Français';
                case 'en':
                    return 'Anglais';
            }
        }

    }
}
