import {
    ModuleWithProviders, NgModule,
    Optional, SkipSelf
} from '@angular/core';

import { AuthHttpModule } from '../../../backendagyl/agyl-angular-auth-http/src';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CookieModule } from 'ngx-cookie';

import { environment } from '../../environments/environment';
import { AlertService } from './alert/alert.service';
import { CacheService } from './cache/cache.service';
import { ConfigService } from './config/config.service';
import { SessionService } from './session/session.service';
import { OwnershipTransferService } from '../shared/owner-transfer/owner-transfer.services';

const modules = [
    AuthHttpModule.forRoot({
        basicToken: environment.apiBasicKey,
        refreshUrl: `${environment.apiUrl}/v1/account/refreshToken`,
        whitelistedDomains: environment.whitelistedDomains
    }),
    CookieModule.forRoot(),
    NgbModule
];

const services = [
    AlertService,
    CacheService,
    ConfigService,
    SessionService,
    OwnershipTransferService
];

/**
 * Module used for the application core features
 */
@NgModule({
    imports: [
        ...modules
    ],
    declarations: [],
    exports: []
})
export class CoreModule {
    constructor (@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error(
                'CoreModule is already loaded. Import it in the AppModule only');
        }
    }

    static forRoot(): ModuleWithProviders<any> {
        return {
            ngModule: CoreModule,
            providers: [
                ...services
            ]
        };
    }
}
