import { floor } from "lodash"

export class Utils
{
    static getDurationString(value: number): string
    {
        let duration = Math.max(value, 0)

        let hours = floor((duration / 3600))

        let minutes = floor((duration / 60) - hours * 60)

        let seconds = floor(duration - hours * 3600 - minutes * 60)

        var hoursString = hours + ""

        if(hoursString.length < 2)
        {
            hoursString = "0" + hoursString
        }

        var minutesString = minutes + ""

        if(minutesString.length < 2)
        {
            minutesString = "0" + minutesString
        }

        var secondsString = seconds + ""

        if(secondsString.length < 2)
        {
            secondsString = "0" + secondsString
        }

        return hoursString + ":" + minutesString + ":" + secondsString
    }

    static clearNulls(input: Object): Object
    {
        const entries = Object.entries(input)
        const filtered = entries.filter(([k,v]) => 
        {
            if (v === null || v === "")
            {
                return false
            }
            else 
            {
                return true
            }
        })
        
        return Object.fromEntries(filtered)
    }
}