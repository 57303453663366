import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
    Router,
    Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {AlertService} from '../../core/alert/alert.service';
import {User} from './user.model';
import {UsersService} from './users.service';

@Injectable()
export class UserResolver implements Resolve<User | boolean> {
    constructor(private router: Router, private alertService: AlertService, private usersService: UsersService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User | boolean> {
        const userId = route.params['userId'];
        return this.usersService.getUser(userId)
            .pipe(
                catchError((error: HttpErrorResponse) => 
                {
                    if (error.status === 404) {
                        this.router.navigate(['/offers']).then(() => {
                            this.alertService.push({
                                type: 'error',
                                title: 'Not found',
                                message: 'Offer could not be found'
                            });
                        });
                    } else if (error.status >= 500) {
                        this.router.navigate(['/server-error']);
                    }
                    return of(false);
                })
            );
    }
}
