import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormHelper } from '../../../backendagyl/agyl-angular-utils/src';

import { ConfigService, SelectOption, SessionService } from '../core';
import { AccountService, AuthSession } from '../shared';
import { HttpErrorResponse } from '@angular/common/http';
import { NgZone } from '@angular/core';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Component({
    selector: 'ag-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    loginErrorCode: number;
    loginForm: FormGroup;
    languageOptions$: Observable<SelectOption[]>;
    isLoginSubmitting = false;
    invalidUser = false;

    constructor(private configService: ConfigService,
                private accountService: AccountService,
                private formBuilder: FormBuilder,
                private router: Router,
                private sessionService: SessionService,
                private translateService: TranslateService,
                private route: ActivatedRoute,
                private ngZone: NgZone) {
    }

    ngOnInit() 
    {
        this.loginForm = this.formBuilder.group({
            'email': new FormControl('', [Validators.required, this.idValidator]),
            'password': new FormControl('', Validators.required),
        });

        this.languageOptions$ = this.configService.getLanguageOptions();
    }
    
    idValidator(control: AbstractControl): ValidationErrors | null 
    {
        if(control.value == null || control.value.length == 0)
        {
            return null
        }

        var isValidNumber = false

        try 
        {
            const phoneUtil = PhoneNumberUtil.getInstance();

            let internationalNumber = control.value.replace(/[^0-9.]/g, '');
            const phoneNumberLength = internationalNumber.length;

            if (phoneNumberLength >= 10) 
            {
                if (phoneNumberLength === 10) 
                {
                    internationalNumber = `+1${internationalNumber}`;
                } 
                else 
                {
                    internationalNumber = `+${internationalNumber}`;
                }

                isValidNumber = phoneUtil.isValidNumber(phoneUtil.parse(internationalNumber));
            } 
        } 
        catch (e) 
        {
            isValidNumber = false
        }

        if(isValidNumber || control.value.match(FormHelper.emailRegex))
        {
            return null
        }

        return { pattern: true };
    };

    get currentLanguage(): string {
        return this.translateService.currentLang.toUpperCase();
    }

    isCurrentLanguage(languageCode: string): boolean {
        return this.translateService.currentLang === languageCode;
    }

    onLanguageSelected(languageCode: string) {
        if (this.translateService.currentLang !== languageCode) {

            languageCode = (languageCode === 'es') ? 'en' : languageCode;
            this.translateService.use(languageCode);
        }
    }

    onLoginSubmit(formData) {
        this.isLoginSubmitting = true;

        this.accountService.login(formData.email.toLowerCase(), formData.password).subscribe((session: AuthSession) =>
         {
            this.ngZone.run(() => 
            {
                this.loginErrorCode = null;
                this.isLoginSubmitting = false;
                
                this.sessionService.setSession(session);
                this.sessionService.setLoginStatus(true);
    
                if(session.forgotPassword)
                {
                    this.router.navigate(['/login/password-set']);
                }
                else
                {
                    this.route.queryParams.subscribe(params => 
                    {
                        if(params.returnUrl)
                        {
                            this.router.navigate([params.returnUrl], { queryParams: { fromLogin: true }, queryParamsHandling: 'merge'});
                        }
                        else
                        {
                            this.router.navigate(['/']);
                        }
                    })
                }
            })
        }, 
        (errorReponse: HttpErrorResponse) => 
        {
            this.ngZone.run(() => 
            {
                this.loginErrorCode = errorReponse.error.code as number;
                this.isLoginSubmitting = false;
                this.invalidUser = true;
            })
        });
    }
}
