import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from '../../core';
import { OwnershipTransferService } from '../../../app/shared/owner-transfer/owner-transfer.services';
import * as CryptoJS from 'crypto-js';

@Component({
    selector: 'ag-invitation-verification',
    templateUrl: './invitation-verification.component.html',
    styleUrls: ['./invitation-verification.component.scss'],
})
export class InvitationVerificationComponent implements OnInit 
{
    invitationId: string
    token: string

    ownershipTransferInvitationSalt = 'ycQ5ywVoGpavPegQu64LVHhvLtkN8Vm7poKIiKAsxHw4fG4llVTiioAzSXc7smby2yIB7wR4i2j9T3mvBm5N9W6Qmq0F3zc3rEDG'

    constructor(private route: ActivatedRoute, 
                private router: Router, 
                private ownershipTransferService: OwnershipTransferService,
                private translateService: TranslateService,
                private sessionService: SessionService)
    {
    }

    ngOnInit() 
    {
        this.route.queryParams.subscribe(params => 
        {
            if(params.lang)
            {
                this.translateService.use(params.lang)
            }
        });
        
        this.route.params.subscribe(params =>
        {
            this.invitationId = params['invitationId']
            this.token = params['token']


            this.sessionService.getAccount().subscribe(account =>
            {
                let hashString = CryptoJS.SHA256(this.ownershipTransferInvitationSalt + this.invitationId + account.id).toString(CryptoJS.enc.Hex)

                this.route.queryParams.subscribe(queryParams =>
                {
                    if(hashString === queryParams['check'] || queryParams['fromLogin'])
                    {
                        const language: string = this.translateService.currentLang ?? 'en';
                        this.ownershipTransferService.validateInvitation(this.invitationId, this.token, language).subscribe(() => 
                        {
                            this.router.navigate(['invite/transfer/owner/' + this.invitationId]);
                        }, 
                        error => 
                        {
                            this.router.navigate(['invite/error/'], { queryParams: { errorMessage: error.error.errorDescription}});
                        })
                    }
                    else
                    {
                        this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url }});
                    }
                })
            })
        });
    }
}