import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormHelper } from '../../../../backendagyl/agyl-angular-utils/src';
import * as Fingerprint2 from 'fingerprintjs2';

import { Observable, Observer } from 'rxjs';

import { environment } from '../../../environments/environment';
import { AuthSession } from '../../core/session';
import { DetailAccount } from './detail-account.model';
import { Empty } from '../empty';
import { Utils } from '../utils';

/**
 * Services that handles account actions
 */
@Injectable()
export class AccountService {
    constructor(private http: HttpClient
    ) {}


    /**
     * Get the current account information
     * @return {Observable<DetailAccount>}
     */
    getAccount(): Observable<DetailAccount> {
        return this.http.get<DetailAccount>(`${environment.apiUrl}/v1/account`);
    }

    /**
     * Authenticates the user and get the tokens
     * @param email
     * @param password
     * @returns {Observable<AuthSession>}
     */
    login(id: string, password: string): Observable<AuthSession> {

        return new Observable((observer: Observer<AuthSession>) => {

            // Get browser fingerprint
            Fingerprint2.get((components) => {
                const values = components.map(component => component.value);
                const murmur = Fingerprint2.x64hash128(values.join(""), 31);

                var email = null
                var phoneNumber = null

                if(id.match(FormHelper.emailRegex))
                {
                    email = id
                }
                else
                {
                    phoneNumber = id
                }

                const login: any = 
                {
                    email: email,
                    phoneNumber: phoneNumber,
                    password: password,
                    device: {
                        id: murmur,
                        type: 'WEB',
                        model: navigator.userAgent
                    }
                };

                return this.http.post<AuthSession>(`${environment.apiUrl}/v1/account/login`, Utils.clearNulls(login))
                    .subscribe((auth) => {
                        observer.next(auth);
                        observer.complete();
                    }, (error: HttpErrorResponse) => {
                        observer.error(error);
                    });
            });
        });
    }

    /**
     * Resets the passworx
     * @param id
     * @param isPhone
     * @returns {Observable<>}
     */
    passwordReset(id: string, isPhone: boolean): Observable<Empty> 
    {
        var queryParams

        if(isPhone)
        {
            queryParams = { phoneNumber: id }
        }
        else
        {
            queryParams = { email: id }
        }

        return this.http.get<Empty>(`${environment.apiUrl}/v1/account/password/temp`, { params: queryParams })
    }

    /**
     * Changes the password
     * @param email
     * @param temporaryPassword
     * @param newPassword
     * @returns {Observable<Empty>}
     */
    passwordSet(id: string, temporaryPassword: string, newPassword: string): Observable<Empty> 
    {
        return new Observable((observer: Observer<Empty>) =>
         {
            // Get browser fingerprint
            Fingerprint2.get((components) => {
                const values = components.map(component => component.value);
                const murmur = Fingerprint2.x64hash128(values.join(""), 31);

                var email = null
                var phoneNumber = null

                if(id.match(FormHelper.emailRegex))
                {
                    email = id
                }
                else
                {
                    phoneNumber = id
                }

                const passwordSet: any = 
                {
                    email: email,
                    phoneNumber: phoneNumber,
                    oldPassword: temporaryPassword,
                    newPassword: newPassword,
                    device: {
                        id: murmur,
                        type: 'WEB',
                        model: navigator.userAgent
                    }
                };

                return this.http.post<AuthSession>(`${environment.apiUrl}/v1/account/password/set`, Utils.clearNulls(passwordSet))
                    .subscribe((auth) => {
                        observer.next(auth);
                        observer.complete();
                    }, (error: HttpErrorResponse) => {
                        observer.error(error);
                    });
            });
        });
    }
}
