import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AuthHttp } from './auth-http.service';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
    constructor(
        private injector: Injector,
    ) {}

    get authHttp(): AuthHttp {
        return this.injector.get(AuthHttp);
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Do not intercept requests made for refreshes
        if (request.url === this.authHttp.refreshUrl) {
            return next.handle(request);
        }

        // Do not intercept requests made for a non-whitelisted domain
        if (!this.authHttp.isWhitelistedDomain(request)) {
            return next.handle(request);
        }

        return this.handleInterception(request, next);
    }

    private handleInterception(request: HttpRequest<any>, next: HttpHandler) 
    {
        const isAuthenticated = this.authHttp.isAuthenticated;

        let handle$;

        if (isAuthenticated) 
        {
            const isTokenExpired = this.authHttp.isTokenExpired;

            // If token is expired, refresh it before doing request
            if (isTokenExpired) 
            {
                handle$ = this.authHttp.doRefreshToken()
                    .pipe(
                        switchMap(() => 
                        {
                            // Append the newly refreshed bearer token to the request
                            request = request.clone(
                            {
                                setHeaders: 
                                {
                                    ['Authorization']: this.authHttp.bearerToken,
                                }
                            });
                            
                            return next.handle(request);
                        })
                    )
            } 
            else 
            {
                // Append the bearer token to the request
                request = request.clone(
                {
                    setHeaders: 
                    {
                        ['Authorization']: this.authHttp.bearerToken,
                    }
                });

                handle$ = next.handle(request);
            }
        } 
        else 
        {
            // If we are not authenticated, we simply add basic token
            request = request.clone(
            {
                setHeaders: 
                {
                    ['Authorization']: this.authHttp.basicToken,
                }
            });
            handle$ = next.handle(request);
        }

        return handle$
            .pipe(
                // If the response is a 401 and user is authenticated, try to refresh token and retry
                catchError(error => 
                {
                    if (error instanceof HttpErrorResponse)
                    {
                        switch ((<HttpErrorResponse>error).status)
                        {
                            case 401:
                                return this.handle401Error(request, next);
                        }
                        return Observable.throw(error);
                    } 
                    else 
                    {
                        return Observable.throw(error);
                    }
                })
            );
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        return this.authHttp.doRefreshToken()
            .pipe(
                switchMap(() => {
                    // Append the newly refreshed bearer token to the request
                    request = request.clone({
                        setHeaders: {
                            ['Authorization']: this.authHttp.bearerToken,
                        }
                    });
                    return next.handle(request);
                }, error => {
                    return Observable.throw(error);
                })
            );
    }
}