
<div class="container-fluid">
    <img id="logo" src="/assets/img/logo/LogoElmec.png" alt="Logo">

    <div class="center">
        <span id="title">
            <span translate>INVITATION.OWNERSHIP_TRANSFER</span>
            <span translate>INVITATION.COMPLETED</span>
        </span>
    </div>
    <div class="center">
        <span id="main-text">
            <span translate>INVITATION.COMPLETED_DESCRIPTION_1</span>
            <span translate><b>{{ stationName }}</b></span>
            <span translate>INVITATION.COMPLETED_DESCRIPTION_2</span>
            <span translate><b>{{ currentOwnerName }}.</b></span>
        </span>
    </div>
    <div class="center">
        <span id="main-text">
            <span translate>INVITATION.COMPLETED_DESCRIPTION_3</span>
            <span style="color: green;" translate>INVITATION.COMPLETED_DESCRIPTION_4</span>. 
        </span>
    </div>

    <div class="center">
        <span id="main-text">
            <span translate>INVITATION.COMPLETED_DESCRIPTION_5</span>
            <b><span translate>INVITATION.COMPLETED_DESCRIPTION_6</span></b>
            <span translate>INVITATION.COMPLETED_DESCRIPTION_7</span>
            <b><span translate>INVITATION.COMPLETED_DESCRIPTION_8</span></b>
            <span translate>INVITATION.COMPLETED_DESCRIPTION_9</span>
        </span>
    </div>

    <div class="center">
        <span id="sub-text">
            <span translate>INVITATION.CLOSE_WEBPAGE</span>
        </span>
    </div>
</div>