// ANGULAR
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// THIRD PARTIES / MODULES
import { DialogModule } from '@progress/kendo-angular-dialog';
import { TranslateModule } from '@ngx-translate/core';

// SERVICES
import { AccountService } from './account';
import { BilledService } from './billed';
import { CategoriesService } from './categories';
import { CurrentSessionsService } from './current-sessions';
import { ConfirmDialogService } from './dialogs';
import { StationListStore, StationsService } from './stations';
import { UsersService } from './users';
import { StatsService } from './stats';
import { SubscriptionService } from './users/subscription.service';

// COMPONENTS
import { AlertsComponent } from './alerts/alerts.component';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';

// DIRECTIVES
import { ButtonLoaderDirective } from './loaders/button-loader.directive';
import { IfValidationErrorDirective } from './validation/if-validation-error.directive';
import { ValidationStateDirective } from './validation/validation-state.directive';

// PIPES
import { SharedPipesModule } from './shared-pipes.module';
import { ReportsService } from './reports/reports.service';
import { OwnershipTransferService } from './owner-transfer/owner-transfer.services';

const modules = [
    // Angular modules
    CommonModule,
    DialogModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    // App Modules
    SharedPipesModule
];

const services = [
    AccountService,
    BilledService,
    CategoriesService,
    ConfirmDialogService,
    CurrentSessionsService,
    OwnershipTransferService,
    UsersService,
    ReportsService,
    StatsService,
    StationsService,
    StationListStore,
    SubscriptionService
];

const components = [
    AlertsComponent,
    ConfirmDialogComponent
];

const directives = [
    ButtonLoaderDirective,
    IfValidationErrorDirective,
    ValidationStateDirective
];

@NgModule({
    imports: [
        ...modules
    ],
    declarations: [
        ...components,
        ...directives
    ],
    exports: [
        ...components,
        ...directives,
        ...modules
    ],
    providers: [
        ...services,
    ],
})
export class SharedModule {
}
