import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ConfirmDialogService {

    constructor(
        private dialogService: DialogService,
        private translateService: TranslateService
    ) {}

    /**
     * Opens an action confirmation dialog
     * @param {string} titleKey
     * @param {string} messageKey
     * @returns {Observable<boolean>}
     */
    doActionConfirm(titleKey?: string, messageKey?: string): Observable<boolean> {
        const dialog: DialogRef = this.dialogService.open({
            title: this.translateService.instant(titleKey || 'DIALOG.CONFIRM_ACTION.TITLE'),
            content: this.translateService.instant(messageKey || 'DIALOG.CONFIRM_ACTION.MESSAGE'),
            actions: [
                { action: false, text: this.translateService.instant('BUTTON.NO') },
                { action: true, text: this.translateService.instant('BUTTON.YES'), primary: true }
            ]
        });

        return dialog.result.pipe(map((result) => {
            if (result instanceof DialogCloseResult) {
                return false;
            } else {
                return result['action'];
            }
        }));
    }

    doDeactivateConfirm(): Observable<boolean> {
        const dialog: DialogRef = this.dialogService.open({
            title: this.translateService.instant('DIALOG.UNSAVED_CHANGES.TITLE'),
            content: this.translateService.instant('DIALOG.UNSAVED_CHANGES.MESSAGE'),
            actions: [
                { action: false, text: this.translateService.instant('BUTTON.NO') },
                { action: true, text: this.translateService.instant('BUTTON.YES'), primary: true }
            ]
        });

        return dialog.result.pipe(map((result) => {
            if (result instanceof DialogCloseResult) {
                return false;
            } else {
                return result['action'];
            }
        }));
    }
}
