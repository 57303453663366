import { EncodingHelper } from '../encoding/encoding.helper';

/**
 * Class for http utilities
 */
export class HttpHelper {

    /**
     * Generate http headers with bearer authentication
     * @param accessToken
     * @param options
     * @returns {Headers}
     */
    static generateAuthHeaders(accessToken: string, options?: HttpHeaderOptions): Headers {
        if(!options) {
            options = { };
        }

        return new Headers({
            'Accept': options.accept || 'application/json',
            'Accept-Language': options.acceptLang || 'en',
            'Authorization': 'Bearer ' + accessToken,
            'Content-Type': options.contentType || 'application/json'
        });
    }

    /**
     * Generate http headers with basic authentication
     * @param accessToken
     * @param options
     * @returns {Headers}
     */
    static generateBasicHeaders(accessToken: string, options?: HttpHeaderOptions): Headers {
        if(!options) {
            options = { };
        }

        return new Headers({
            'Accept': options.accept || 'application/json',
            'Accept-Language': options.acceptLang || 'en',
            'Authorization': 'Basic ' + accessToken,
            'Content-Type': 'application/json'
        });
    }

    /**
     * Generate http headers
     * @param options
     * @returns {Headers}
     */
    static generateHeaders(options?: HttpHeaderOptions): Headers {
        if(!options) {
            options = { };
        }

        return new Headers({
            'Accept': options.accept || 'application/json',
            'Accept-Language': options.acceptLang || 'en',
            'Content-Type': options.contentType || 'application/json'
        });
    }

    /**
     * Generates a base64 encoded token using the application username and password
     * @param appUserName
     * @param appPassword
     * @returns {string}
     */
    static generateBasicToken(appUserName: string, appPassword: string): string {
        if(typeof btoa !== 'undefined' && btoa) {
            return btoa(appUserName + ':' + appPassword);
        }
        return EncodingHelper.btoa(appUserName + ':' + appPassword);
    }
}

export interface HttpHeaderOptions {
    accept?: string;
    acceptLang?: string;
    contentType?: string;
}
