import { Pipe, PipeTransform } from '@angular/core';

/**
 * Converts a textArea to an html value
 */
@Pipe({
    name: 'textAreaToHtml'
})

export class TextAreaToHtmlPipe implements PipeTransform {
    constructor(
    ) {}

    transform(input: string): string {
        const cleanedInput = input.replace(/<(?:.|\n)*?>/gm, '');
        return cleanedInput.replace('\n', '<br/>')
    }
}
