import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core';
import { OfferGuard } from './core';
import { OwnershipTransferComponent } from './ownership-transfer/ownership-transfer.component';

const routes: Routes = [
    {
        path: 'invite/transfer/owner/**',
        component: OwnershipTransferComponent,
        canActivate: [AuthGuard]
    },
    {
        path: '**',
        redirectTo: '/not-found'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
    providers: [
        AuthGuard,
        OfferGuard
    ]
})
export class AppRoutingModule {
}
