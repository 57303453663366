import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SessionService } from './session.service';
import { environment } from '../../../environments/environment';

/**
 * Service that is used to guard routes related to offers
 */
@Injectable()
export class OfferGuard implements CanActivate {
    constructor(private sessionService: SessionService, private router: Router) {
    }

    canActivate() {
        return environment.showOffers;
    }
}
