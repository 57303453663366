
<div class="container-fluid">
    <img id="logo" src="/assets/img/logo/LogoElmec.png" alt="Logo">

    <div class="center">
        <span id="title">
            <span translate>INVITATION.OWNERSHIP_TRANSFER</span>
            <span style="color: red;" translate>INVITATION.ERROR</span>
        </span>
    </div>
    <div class="center">
        <span id="main-text" translate>
            INVITATION.ERROR_LABEL
        </span>
    </div>
    <div class="center">
        <span id="main-text">
            <span style="color: red;">{{ errorMessage }}</span>. 
        </span>
    </div>

    <div class="center">
        <span id="sub-text" translate>
            INVITATION.CLOSE_WEBPAGE 
        </span>
    </div>
</div>