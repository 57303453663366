import { animate, style, transition, trigger } from '@angular/animations';
import {
    Component,
    OnInit,
    Input,
} from '@angular/core';

import { Alert } from '../../core/index';

@Component({
    selector: 'ag-alerts',
    templateUrl: './alerts.component.html',
    styleUrls: ['./alerts.component.scss'],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                style({transform: 'translateY(-100%)', opacity: 0}),
                animate(200, style({transform: 'translateY(0)', opacity: 1}))
            ]),
            transition(':leave', [
                animate(100, style({opacity: 0})),
                animate(50, style({height: 0}))
            ])
        ])
    ]
})
export class AlertsComponent implements OnInit {
    shownAlerts: Array<Alert> = [];
    timeouts: Array<any> = [];

    @Input() duration: number;

    constructor() {
    }

    ngOnInit() {
    }

    onDismissClick(index: number) {
        if (this.timeouts.length > index) {
            clearTimeout(this.timeouts[index]);
        }
        this.shownAlerts.splice(index, 1);
    }

    show(alert: Alert) {
        // Add new alert
        const timeout = setTimeout(() => {

            // Remove oldest alert
            this.shownAlerts.splice(0, 1);
            clearTimeout(timeout);

        }, this.duration || 1000);
        this.timeouts.push(timeout);
        this.shownAlerts.push(alert);
    }
}
