import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: 'ag-invitation-error',
    templateUrl: './invitation-error.component.html',
    styleUrls: ['./invitation-error.component.scss'],
})
export class InvitationErrorComponent implements OnInit 
{
    errorMessage= ""

    constructor(private route: ActivatedRoute)
    {
        
    }

    ngOnInit()
    {
        this.route.queryParams.subscribe(params => 
        {
            this.errorMessage = params.errorMessage
        })
    }
}
