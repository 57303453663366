import * as moment from 'moment';

/**
 * Class for form utilities
 */
export class FormHelper {
    /**
     * @deprecated has been change for coordinatesLat and
     *  coordinatesLng
     * @type {RegExp}
     */
    static coordinatesRegex: RegExp = /^-?[0-9]{1,2}\.[0-9]{6}/;

    static coordinatesLatRegex: RegExp = /^-?[0-9]{1,2}\.[0-9]{6}/;
    static coordinatesLngRegex: RegExp = /-?[1-2]?[0-9]{1,2}\.?[0-9]{6}/;
    static emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    static friendlyUrlStringRegex: RegExp = /^[a-zA-Z0-9_-]*$/;
    static hexColorRegex: RegExp = /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i;
    static identifierRegex: RegExp = /^[a-zA-Z0-9-_]*$/;
    static maskedPhoneRegex: RegExp = /\([0-9]{3}\)-[0-9]{3}-[0-9]{4}/;
    static nullableMaskedPhoneRegex: RegExp = /(\([_]{3}\)-[_]{3}-[_]{4}$)|(\([0-9]{3}\)-[0-9]{3}-[0-9]{4}$)/;
    static optionalProtocolUrlRegex: RegExp = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
    static positiveNumberRegex: RegExp = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
    static phoneRegex: RegExp = /[^0-9]+/g;
    static numbersRegex: RegExp = /^[0-9]*$/;
    static urlRegex: RegExp = /(([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;

    /**
     * Strips phone number from all characters beside numbers
     * @param input
     * @returns {string}
     */
    static cleanPhoneNumber(input: string): string {
        return input.replace(this.phoneRegex, '');
    }

    /**
     * Validate Latitude coordinates input using a regex
     * @deprecated has been update to validateLatCoordinates()
     * and validateLngCoordinates
     * @param input
     * @returns {boolean}
     */
    static validateCoordinates(input: string): boolean {
        return this.coordinatesRegex.test(input);
    }

    /**
     * Validate Latitude coordinates input using a regex
     * @param input
     * @returns {boolean}
     */
    static validateLatCoordinates(input: string): boolean {
        return this.coordinatesLatRegex.test(input);
    }

    /**
     * Validate Longitude coordinates input using a regex
     * @param {string} input
     * @return {boolean}
     */
    static validateLngCoordinates(input: string): boolean {
        return this.coordinatesLngRegex.test(input);
    }

    /**
     * Validate dates bases on format
     * @param input
     * @param format
     * @returns {boolean}
     */
    static validateDate(input: string, format?: string): boolean {
        if (!format) {
            format = 'YYYY-MM-DD';
        }
        let date: moment.Moment = moment(input, format, true);

        return date.isValid();
    }

    /**
     * Validates the email using an emailRegex
     * @param input
     * @returns {boolean}
     */
    static validateEmail(input: string): boolean {
        return this.emailRegex.test(input);
    }

    /**
     * Validates friendly url string using a regex
     * @param input
     * @returns {boolean}
     */
    static validateFriendlyUrlString(input: string): boolean {
        return this.friendlyUrlStringRegex.test(input);
    }

    /**
     * Validates hexColor string using a regex
     * @param input
     * @returns {boolean}
     */
    static validateHexColor(input: string): boolean {
        return this.hexColorRegex.test(input);
    }

    /**
     * Validates positive number using a regex
     * @param input
     * @returns {boolean}
     */
    static validatePositiveNumber(input: string): boolean {
        return this.positiveNumberRegex.test(input);
    }

    /**
     * Validates an url using a regex;
     * @param {string} input
     * @returns {boolean}
     */
    static validateUrl(input: string): boolean {
        return this.urlRegex.test(input);
    }
}
