import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { AuthHttp, AuthTokens } from '../../../../backendagyl/agyl-angular-auth-http/src';
import { CookieService } from 'ngx-cookie';
import 'rxjs/add/observable/throw';
import { catchError, map, share, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { DetailAccount } from '../../shared/account';
import { AccountService } from '../../shared/account';
import { AuthSession } from './auth-session.model';
import { Observable, of, ReplaySubject } from 'rxjs';
/**
 * Services that handles session stateOptions
 */
@Injectable()
export class SessionService {
    isLoggedIn$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

    private accessToken: string;
    private account$: Observable<DetailAccount>;
    private account: DetailAccount;
    private email: string;
    private firstName: string;
    private isLoggedIn = false;
    private role: string;

    constructor(private authHttp: AuthHttp,
                private cookieService: CookieService,
                private router: Router,
                private accountService: AccountService) {
        const sessionCookie: any = this.cookieService.getObject(this.sessionCookieName) as any;

        if (sessionCookie && sessionCookie.email && sessionCookie.token) {
            this.initAuthSession(sessionCookie);
        } else {
            this.isLoggedIn$.next(false);
        }

        this.observeAuthSession();
    }

    get displayName$(): Observable<string> {
        return this.getAccount()
            .pipe(map((account: DetailAccount) => {
                return `${account.firstName} ${account.lastName}`;
            }));
    }


    get sessionCookieName(): string {
        return `${environment.cookiePrefix}_session`;
    }

    /**
     * Deletes session
     */
    deleteSession() {
        this.isLoggedIn = false;
        this.account = null;
        this.isLoggedIn$.next(false);
        this.cookieService.remove(this.sessionCookieName);
        this.authHttp.setTokens(null);

        this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url }});
    }

    getAccessToken() {
        return this.accessToken;
    }

    /**
     * Returns the email of the user
     * @returns {string}
     */
    getEmail(): string {
        return this.email;
    }

    /**
     * Returns the login status
     * @returns {boolean} isLoggedIn
     */
    getLoginStatus(): boolean {
        return this.isLoggedIn;
    }

    /**
     * Gets the user detail of the current user
     * @returns {Observable<DetailUser>}
     */
    getAccount(): Observable<DetailAccount> {
        if (this.account) {
            return of(this.account);
        }

        if (!this.account$) {
            this.account$ = this.accountService
                .getAccount()
                .pipe(tap((account: DetailAccount) => {
                    this.account = account;
                    this.account$ = null;
                }))
                .pipe(catchError((error) => {
                    this.account$ = null;
                    this.deleteSession();
                    return Observable.throw(error);
                }))
                .pipe(share());
        }

        return this.account$;
    }

    resetAccount() {
        this.account = null;
        this.account$ = null;
    }

    /**
     * Sets the login status
     * @param loginStatus
     */
    setLoginStatus(loginStatus: boolean) {
        this.isLoggedIn = loginStatus;
        this.isLoggedIn$.next(loginStatus);
    }

    /**
     * Sets current session
     * @param email
     * @param firstName
     * @param role
     * @param authTokens
     */
    setSession(authSession: AuthSession) 
    {
        this.account = null;
        this.email = authSession.email;
        this.firstName = authSession.firstName;
        this.role = authSession.role;

        const authTokens: AuthTokens = {
            accessToken: authSession.accessToken,
            expiresIn: authSession.expiresIn,
            issuedAt: authSession.issuedAt * 1000,
            refreshToken: authSession.refreshToken,
            forgotPassword: authSession.forgotPassword
        };

        this.updateCookie(this.email, this.firstName, this.role, authTokens);
        this.authHttp.setTokens(authTokens);
    }

    private initAuthSession(session: any) 
    {
        this.accessToken = session.token.accessToken;
        this.email = session.email;
        this.firstName = session.firstName;
        this.role = session.role;

        this.setLoginStatus(true);

        this.authHttp.setTokens(session.token);
    }

    private observeAuthSession() {
        this.authHttp.tokens$.subscribe((authTokens: AuthTokens) => {
            if (authTokens) {
                this.updateCookie(this.email, this.firstName, this.role, authTokens);
            } else {
                this.deleteSession();
            }
        });
    }

    private updateCookie(email: string, firstName, role: string, tokens: AuthTokens) {
        this.cookieService.putObject(this.sessionCookieName, {
            email: email,
            firstName: firstName,
            role: role,
            token: tokens,
        });
    }
}
