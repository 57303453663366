import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {environment} from '../../../environments/environment';
import {User} from './user.model';
import {DataView} from '../';
import { Observable } from 'rxjs';

/**
 * Services that handles stats actions
 */
@Injectable()
export class UsersService {
    constructor(private http: HttpClient) {
    }

    /**
     * Get a user with specified id
     * @param {string} userId
     * @returns {Observable<User>}
     */
    getUser(userId: string) {
        return this.http.get<User>(`${environment.apiUrl}/admin/v1/accounts/${userId}`);
    }

    updateUser(user: User): Observable<string> {
        return this.http.put<string>(`${environment.apiUrl}/admin/v1/accounts/${user.id}`, user);
    }

    /**
     * delete a user with specified id
     * @param {string} userId
     */
    deleteUser(userId: string) {
        return this.http.delete(`${environment.apiUrl}/admin/v1/accounts/${userId}`);
    }

    /**
     * Get offers
     * @return {Observable<DataView<User[]>>}
     */
    getUsers(limit?: number, offset?: number, query?: string, activeSubscription?: string): Observable<DataView<User[]>> {
        let params = new HttpParams();

        if (offset) {
            params = params.set('offset', offset.toFixed());
        }

        if (limit) {
            params = params.set('limit', limit.toFixed());
        }

        if (query) {
            params = params.set('q', query);
        }

        if (activeSubscription) {
            params = params.set('subscription.status', activeSubscription);
        }

        return this.http.get<DataView<User[]>>(`${environment.apiUrl}/v1/account`, {params: params});
    }
}
